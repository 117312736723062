import type { PropsWithChildren } from 'react';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import type { ClientEngineContextValue } from '@cyferd/client-engine';
import { ClientEngineProvider, GeneralModel, GlobalBehavior, noop, useTranslate } from '@cyferd/client-engine';

import { ENV } from '@constants';
import type { GeneralModel as LocalGeneralModel } from '@models';
import { logger, useOnIncomingNotification, useUrl } from '@utils';
import { componentRecord } from '../components/componentRecord';
import { useNotificationSelector } from './notification';
import { useUserSelector } from './user';
import { useViewSelector } from './view';
import { useWSContext } from './WSProvider';
import { useUIStore } from './zustand/bridgeProvider';

const useDataSelector = () => useSelector((state: LocalGeneralModel.State) => state.global);
const useActiveModalSelector = () => useSelector((state: LocalGeneralModel.State) => state.ui.activeModalList);

const config: GeneralModel.EngineConfig = { validateComponentProps: false };

export const EngineProviders = memo(({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const onAlert = useUIStore(state => state.onAlert);
  const onConfirm = useUIStore(state => state.onConfirm);
  const runtimeTheme = useUIStore(state => state.runtimeTheme);
  const size = useUIStore(state => state.size);
  const { resolvedLanguage } = useTranslate();

  const platformConfig: GeneralModel.PlatformConfig = useMemo(
    () => ({
      app: GeneralModel.CyferdApp.WEB,
      env: ENV.ENVIRONMENT,
      fileUploadUrl: ENV.FILES_URL,
      theme: runtimeTheme,
      builderUrl: `${ENV.ADMIN_URL}/`,
      clientAppUrl: `${document.location.origin}/`,
      size,
      lang: resolvedLanguage
    }),
    [resolvedLanguage, runtimeTheme, size]
  );

  const getAbsolutePath = (str: string) => `/${str.replace(/^\//, '')}`;

  const navigation = useMemo(
    () =>
      ({
        navigateTo: ({ path, external, openInNewTab }) => {
          if (openInNewTab || external) return window.open(external ? path : getAbsolutePath(path), '_blank').focus();
          navigate(getAbsolutePath(path));
        },
        navigateBack: () => navigate(-1),
        navigateForward: () => navigate(1),
        navigateReplace: ({ path }) => navigate(getAbsolutePath(path), { replace: true })
      }) as ClientEngineContextValue['navigation'],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const modalInteraction = useMemo(() => ({ onConfirm, onAlert }), [onAlert, onConfirm]);

  const platformInteraction = useMemo(
    () =>
      ({
        onDownloadFile: payload => {
          const download = document.createElement('a');
          download.setAttribute('download', payload?.filename || 'file');
          download.setAttribute('href', payload?.data);
          download.click();
        }
      }) as ClientEngineContextValue['platformInteraction'],
    []
  );

  const notificationInteraction = useMemo(
    () =>
      ({
        onIncoming: useOnIncomingNotification,
        onRead: () => noop
      }) as ClientEngineContextValue['notificationInteraction'],
    []
  );

  return (
    <ClientEngineProvider
      {...{
        componentRecord,
        config,
        logger,
        modalInteraction,
        navigation,
        notificationInteraction,
        platformConfig,
        platformInteraction,
        useActiveModalSelector,
        useDataSelector,
        useNotificationSelector,
        useUrl,
        useUserSelector,
        useViewSelector,
        useWSContext
      }}
    >
      <GlobalBehavior>{children}</GlobalBehavior>
    </ClientEngineProvider>
  );
});
