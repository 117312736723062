import { useMemo, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router';

import type { UserModel } from '@cyferd/client-engine';
import { ApiModel } from '@cyferd/client-engine';

import { BUILDER_DESCRIPTION, BUILDER_ROUTE, QUERY_PARAM, SECTION_NAME, TRANS } from '@constants';
import { useQueryParamState } from '@utils/useQueryParamState';
import { EditorHome, useEditorHomeEffect, useEditorHomeOnRemove, useEditorHomeOnSave } from '../shared/EditorHome';
import { AppBondEditor } from './components/AppBondEditor';
import { GeneralInfo } from './components/GeneralInfo';
import { GeneralList } from './components/GeneralList';

export const UserEditor = () => {
  const { pathname } = useLocation();
  const [queryParamState] = useQueryParamState();
  const itemId = queryParamState[QUERY_PARAM.RECORD_ID];
  const [original, setOriginal] = useState<UserModel.User>();
  const [isLoading, setLoading] = useState<boolean>(true);

  const routeList = useMemo(
    () => [
      { route: BUILDER_ROUTE.USER_EDITOR.CHILDREN.LIST, label: null },
      { route: BUILDER_ROUTE.USER_EDITOR.CHILDREN.GENERAL, label: TRANS.client.nav.builder.tabs.details },
      { route: BUILDER_ROUTE.USER_EDITOR.CHILDREN.BOND, label: TRANS.client.nav.builder.tabs.linkedApps }
    ],
    []
  );

  const onSave = useEditorHomeOnSave(ApiModel.ApiEntity.USER);
  const onRemove = useEditorHomeOnRemove(ApiModel.ApiEntity.USER);

  useEditorHomeEffect({ itemId, collectionId: ApiModel.ApiEntity.USER, setOriginal, setLoading });

  return (
    <EditorHome
      isLoading={isLoading}
      editorTitle={SECTION_NAME.USERS}
      title={original?.fullName}
      subtitle={BUILDER_DESCRIPTION.USERS}
      icon={original?.recordImage}
      color={original?.recordColor}
      item={original}
      /** header */
      routeList={routeList}
      rootPath={BUILDER_ROUTE.USER_EDITOR.ROOT}
      isCurrentDraft={false}
      hideSubHeader={['/', BUILDER_ROUTE.USER_EDITOR.ROOT, `${BUILDER_ROUTE.USER_EDITOR.ROOT}${BUILDER_ROUTE.USER_EDITOR.CHILDREN.LIST}`].includes(pathname)}
      onSave={onSave}
      onRemove={onRemove}
      onSetOriginalValue={setOriginal}
      activityLogConfig={{ collectionId: ApiModel.ApiEntity.USER, recordId: itemId }}
    >
      <Routes>
        <Route path={BUILDER_ROUTE.USER_EDITOR.CHILDREN.LIST} element={<GeneralList />} />
        <Route path={BUILDER_ROUTE.USER_EDITOR.CHILDREN.GENERAL} element={<GeneralInfo />} />
        <Route path={BUILDER_ROUTE.USER_EDITOR.CHILDREN.BOND} element={<AppBondEditor />} />
        <Route path="*" element={<GeneralList />} />
      </Routes>
    </EditorHome>
  );
};
