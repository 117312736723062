import { DevMenuData } from '../DevMenuData';
import { TabList } from '../TabList';
import { CyLayout } from '../../smart/CyLayout/CyLayout';
import { DevMenuInspector } from '../DevMenuInspector';
import { DevMenuActions } from '../DevMenuActions';
import { ViewModel } from '@cyferd/client-engine';

export enum DevMenuTab {
  ACTIONS = 'Traffic',
  DATA = 'Data',
  INSPECT = 'Resources'
}

export interface DevMenuProps {
  activeTab: string;
  onChangeTab: (event: DevMenuTab) => void;
}

export const DevMenu = ({ activeTab = DevMenuTab.ACTIONS, onChangeTab }: DevMenuProps) => {
  return (
    <div data-testid="dev-menu">
      <TabList activeTab={activeTab} tabList={Object.values(DevMenuTab).map(title => ({ title }))} onChangeTab={onChangeTab as any} />
      <CyLayout itemHeight={ViewModel.LayoutHeightPreset.REMAINING}>
        {activeTab === DevMenuTab.ACTIONS && <DevMenuActions />}
        {activeTab === DevMenuTab.DATA && <DevMenuData />}
        {activeTab === DevMenuTab.INSPECT && <DevMenuInspector />}
      </CyLayout>
    </div>
  );
};

DevMenu.displayName = 'DevMenu';
