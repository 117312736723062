import { memo, useCallback, useContext, useMemo, useState } from 'react';

import type { FlowModel } from '@cyferd/client-engine';
import { ClientEngineContext, ErrorBoundary, ViewModel } from '@cyferd/client-engine';

import { Adder } from '@components/elements/Adder';
import { CyForm } from '@components/smart/CyForm';
import { EmptyState } from '@components/elements/EmptyState';
import { Layout } from '@components/elements/Layout';
import { Modal } from '@components/elements/Modal';
import { CONTAINER_APP_PADDING, TRANS } from '@constants';
import { getFlowInputList } from '@models/flow';

import type { EditorContextValue } from '../../../../shared/EditorHome';
import { EditorContext } from '../../../../shared/EditorHome';
import { FlowChart } from './components/FlowChart';
import { FlowSidebar } from './components/FlowSidebar';
import {
  // edgeModel,
  initialStepModel,
  inputModel,
  outputModel,
  stepModel
  // stepNotesModel
} from './models';
import { flowActionOptions } from './resources';
import { useFlowHandlers } from './hooks/useFlowHandlers';
import { defaultStepImage, initialStepId, isDispatch } from './constants';

export const FlowDefinition = memo(() => {
  const { useUserSelector } = useContext(ClientEngineContext);
  const user = useUserSelector();
  const { item, setItem } = useContext<EditorContextValue<FlowModel.Flow>>(EditorContext);
  const [activeStepKey, setActiveStepKey] = useState<string>(null);
  const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
  // const [notesModalVisible, setNotesModalVisible] = useState<boolean>(false);
  const [stepParentId, setStepParentId] = useState<string>(null);

  const onToggleAddModal = useCallback((key = null) => {
    setStepParentId(key);
    setAddModalVisible(p => !p);
  }, []);

  const {
    onAdd,
    onClearAllDebug,
    onClone,
    // onEdgeChange,
    onEdgeTypeToggle,
    onEdgeRemove,
    onRemove,
    // onSetNotes,
    onStartAdd,
    onStepChange,
    onToggleDebug
  } = useFlowHandlers({ stepParentId, item, setItem, activeStepKey, setActiveStepKey, onToggleAddModal });

  const flowInputList = useMemo(() => getFlowInputList(item), [item]);

  const onActiveStepChange = useCallback(
    (stepKey: string) => {
      const shouldShow = stepKey !== activeStepKey;
      setActiveStepKey(shouldShow ? stepKey : null);
      if (shouldShow && document.getElementById?.(stepKey)) document.getElementById(stepKey).scrollIntoView?.();
    },
    [activeStepKey]
  );

  const stepActions = [
    { icon: 'add', label: 'Add', onClick: onToggleAddModal },
    { icon: 'content_copy', label: 'Clone step', onClick: onClone, shouldHide: key => !key || key === initialStepId },
    // TODO: add note
    // {
    //   color: 'YW_1',
    //   icon: 'note',
    //   label: 'Add notes',
    //   onClick: stepId => {
    //     setActiveStepKey(stepId);
    //     // setNotesModalVisible(true);
    //   },
    //   shouldHide: key => !key || key === initialStepId
    // },
    {
      color: 'OE_2',
      icon: 'pest_control',
      label: 'Toggle debug',
      onClick: onToggleDebug,
      shouldHide: (key, step) => !key || key === initialStepId || isDispatch(step.action)
    },
    { color: 'RD_4', icon: 'delete', label: 'Delete step', onClick: onRemove, shouldHide: key => !key || key === initialStepId }
  ];

  const edgeActions = [
    // TODO: edit edge
    // { icon: 'edit', label: 'Edit', onClick: onEdgeChange },
    {
      color: 'OE_2',
      icon: 'error',
      label: 'Switch to on error',
      onClick: onEdgeTypeToggle,
      shouldHide: (_key, edge) => edge.parentId === initialStepId || edge.isError || isDispatch(edge.parentAction)
    },
    {
      color: 'OE_2',
      icon: 'feedback',
      label: 'Switch to on result',
      onClick: onEdgeTypeToggle,
      shouldHide: (_key, edge) => edge.parentId === initialStepId || !edge.isError || isDispatch(edge.parentAction)
    },
    { color: 'RD_4', icon: 'delete', label: 'Delete', onClick: onEdgeRemove }
  ];

  const stepOptionList = useMemo(
    () => Object.entries(item?.steps || {}).map(([stepId, step]) => ({ value: stepId, label: step.name, description: step?.action })),
    [item?.steps]
  );

  const isFormOpen = item?.steps?.[activeStepKey] || ['initial-step', 'input', 'output'].includes(activeStepKey);

  const formModel = useMemo(() => {
    if (!isFormOpen) return;
    if (activeStepKey === 'input') return inputModel;
    if (activeStepKey === 'output') return outputModel;
    return initialStepModel(stepOptionList, item?.model);
  }, [activeStepKey, stepOptionList, item, isFormOpen]);

  if (!item) return <EmptyState />;

  const formProps: Partial<ViewModel.CyFormProps> = {
    type: ViewModel.CyFormType.DEFAULT,
    autofocusDisabled: true,
    maxColumns: 2,
    // feed evaluator
    ...{ inputList: flowInputList },
    actionListChildren: [
      {
        important: true,
        color: 'NEUTRAL_2',
        helperText: TRANS.client.buttons.close,
        type: ViewModel.CTAType.LINK,
        icon: 'close',
        onClick: () => setActiveStepKey(null) as any
      }
    ],
    actionPosition: ViewModel.ActionPosition.TOP,
    onChange: ({ record }) => setItem({ ...item, ...record }) as any,
    value: { record: item } as any
  };

  return (
    <>
      {!!addModalVisible && (
        <Modal
          type={ViewModel.ModalType.FULL_SCREEN}
          open={true}
          icon={defaultStepImage}
          title="Add step"
          description="Select an action to add a new step"
          onClose={onToggleAddModal}
        >
          <Adder options={flowActionOptions(user)} onSelect={onAdd} />
        </Modal>
      )}
      {/* {!!notesModalVisible && (
        <Modal
          open={true}
          icon='note'
          title="Notes"
          description={item.steps[activeStepKey].name}
          onClose={() => setNotesModalVisible(!notesModalVisible)}
        >
          <CyForm
            {...formProps}
            value={{ record: item.steps[activeStepKey] } as any}
            model={stepNotesModel}
            onChange={({ record }) => onStepChange(activeStepKey, record as any) as any}
          />
        </Modal>
      )} */}
      <div css={CONTAINER_APP_PADDING}>
        <Layout itemHeight={ViewModel.LayoutHeightPreset.REMAINING}>
          <Layout fitToPage={true} type={ViewModel.LayoutType.TWO_ALT_4}>
            <FlowSidebar
              flow={item}
              activeStepKey={activeStepKey}
              onActiveStepChange={onActiveStepChange}
              onToggleAddModal={onToggleAddModal}
              stepActions={stepActions}
            />
            <Layout
              actionListChildren={[
                {
                  type: ViewModel.CTAType.PRIMARY,
                  important: true,
                  label: 'Clear all debug',
                  onClick: onClearAllDebug as any
                },
                {
                  type: ViewModel.CTAType.PRIMARY,
                  important: true,
                  label: 'Add step',
                  onClick: () => onToggleAddModal() as any
                }
              ]}
              itemHeight={ViewModel.LayoutHeightPreset.REMAINING}
              type={isFormOpen ? ViewModel.LayoutType.TWO : ViewModel.LayoutType.FULL}
            >
              <ErrorBoundary>
                <FlowChart
                  flow={item}
                  activeStepId={activeStepKey}
                  initialStepId={initialStepId}
                  onSelect={setActiveStepKey}
                  onChange={onStepChange}
                  onStartAdd={onStartAdd}
                  stepActions={stepActions}
                  edgeActions={edgeActions}
                />
              </ErrorBoundary>

              {isFormOpen && (
                <Layout
                  title={item.steps[activeStepKey]?.name || { 'initial-step': 'Start', input: 'Input', output: 'Output' }[activeStepKey]}
                  subtitle={item.steps[activeStepKey]?.description}
                  image={item.steps[activeStepKey]?.metadata?.image}
                  framed={true}
                >
                  {item?.steps?.[activeStepKey] ? (
                    <CyForm
                      fitToPage={true}
                      {...formProps}
                      value={{ record: item.steps[activeStepKey] } as any}
                      model={stepModel(stepOptionList)}
                      onChange={({ record }) => onStepChange(activeStepKey, record as any) as any}
                      actionListChildren={[
                        {
                          important: true,
                          color: 'NEUTRAL_2',
                          helperText: TRANS.client.buttons.close,
                          type: ViewModel.CTAType.LINK,
                          icon: 'close',
                          onClick: () => setActiveStepKey(null) as any
                        },
                        {
                          important: true,
                          color: 'RD_2',
                          helperText: TRANS.client.buttons.delete,
                          type: ViewModel.CTAType.LINK,
                          icon: 'delete',
                          onClick: () => onRemove(activeStepKey) as any
                        }
                      ]}
                    />
                  ) : (
                    <CyForm {...formProps} model={formModel} />
                  )}
                </Layout>
              )}
            </Layout>
          </Layout>
        </Layout>
      </div>
    </>
  );
});
