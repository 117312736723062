import { memo } from 'react';

import type { FlowModel } from '@cyferd/client-engine';
import { GeneralModel } from '@cyferd/client-engine';

import { SchemaForm } from '../../../../../../shared/SchemaForm';
import { styles } from './styles';

const schema = { label: 'Input form', type: 'any', format: GeneralModel.JSONSchemaFormat.COLLECTION_LITE } as any;

export interface ModelEditorProps {
  value: FlowModel.Flow['model'];
  onChange: (event: FlowModel.Flow['model']) => void;
}

export const ModelEditor = memo(({ value, onChange }: ModelEditorProps) => {
  return (
    <div data-testid="general-info-container" className={styles.container}>
      <SchemaForm schema={schema} value={value} onChange={onChange} />
    </div>
  );
});
