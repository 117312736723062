import { COLOR, GAP } from '@constants';
import { css } from '@emotion/css';

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    padding: 0 ${GAP.S};
  `,
  iframeContainer: css`
    flex: 1;
    border: 2px dashed ${COLOR.BRAND_1};
    padding: ${GAP.S};
    margin: ${GAP.S} 0;
  `,
  iframe: css`
    width: 100%;
    height: 100%;
  `
};
