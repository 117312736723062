import { css } from '@emotion/react';
import { FONT_SIZE, FONT_WEIGHT, GAP } from '@constants';

export const styles = {
  bannerContainer: css`
    width: 100%;
    min-height: 18px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 16px;
    padding: ${GAP.S} ${GAP.M};
    position: fixed;
    left: 0;
    z-index: 11;
    font-size: ${FONT_SIZE.XS};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  `,
  top: css`
    top: 0;
  `,
  bottom: css`
    top: 0;
    @media (min-width: 768px) {
      top: auto;
      bottom: 0;
    }
  `,
  close: css`
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  `
};
