import { useCallback, useContext, useMemo } from 'react';

import { GeneralModel, ViewModel, createUUID } from '@cyferd/client-engine';

import { EmptyState } from '@components/elements/EmptyState';
import { Layout } from '@components/elements/Layout';
import { CONTAINER_APP_PADDING, ENV, TRANS } from '@constants';
import { userInputList } from '@models/user';
import { viewInputList } from '@models/view';
import type { EditorContextValue } from '../../../shared/EditorHome';
import { EditorContext } from '../../../shared/EditorHome';
import { SchemaForm } from '../../../shared/SchemaForm';

const inputList = [...viewInputList, ...userInputList];

const schema: GeneralModel.JSONSchema = {
  $id: createUUID(),
  type: 'object',
  properties: {
    varMap: {
      label: 'Variables',
      description:
        'View variables provide a way to specify references for data, entry parameters like a IDs from the URL, fixed values to be reused, etc. They can be used by referencing "{{vars.[key]}}"',
      type: 'array',
      metadata: { ctaConfig: { label: 'Add variable', image: 'add' }, unlimitedHeight: true },
      items: {
        label: ' ',
        type: 'object',
        required: ['key'],
        properties: {
          key: { type: 'string', label: TRANS.client.fields.titles.key },
          value: { type: 'any', label: TRANS.client.fields.titles.value, format: GeneralModel.JSONSchemaFormat.EVALUATION, default: '' }
        }
      }
    }
  }
};

export const ViewVarEditor = () => {
  const { item, setItem } = useContext<EditorContextValue<ViewModel.View>>(EditorContext);

  const value = useMemo(
    () => ({
      varMap: Object.entries(item?.vars || {}).reduce((total, [key, v]) => [...total, { key, value: v }], [])
    }),
    [item?.vars]
  );

  const onChange = useCallback(
    (event: { varMap: { key: string; value: any }[] }) => {
      setItem({ ...item, vars: event?.varMap?.reduce((t, row, index) => ({ ...t, [row?.key || `key_${index + 1}`]: row?.value }), {}) });
    },
    [item, setItem]
  );

  if (!item) return <EmptyState />;

  return (
    <div css={CONTAINER_APP_PADDING}>
      <Layout itemHeight={ViewModel.LayoutHeightPreset.REMAINING}>
        <Layout framed={true} fitToPage={true}>
          <SchemaForm schema={schema} value={value} onChange={onChange} delayTime={ENV.INPUT_DEBOUNCE_TIME * 2} inputList={inputList} />
        </Layout>
      </Layout>
    </div>
  );
};
