// istanbul ignore file
import { useCallback, useContext, useMemo } from 'react';
import { ErrorBoundary, ViewModel } from '@cyferd/client-engine';

import { BBContainer } from '@components/elements/BBContainer';
import { ModalContainerContext } from '@components/elements/Modal/ModalContainerContext';
import { useGetElementSize } from '@utils';
import { CyReusableActionEffect } from '../CyEffect';
import { CyWrapperContext } from '../CyWrapper';
import { GlobalContext } from '../../../builder/state-mgmt/GlobalState';
import { CyTableHeader } from './components/CyTableHeader';
import { CyTablePagination } from './components/CyTablePagination';
import { CyTableView } from './components/CyTableView';
import { EditErrorModal } from './components/EditErrorModal';
import { EditBulkContext, useBulkEdit, useDataSource, useEditMode, useSaveBulk } from './hooks';
import { styles } from './styles';

const testId = 'cyTable';

export const CyTable = ({
  id,
  componentName,
  fitToPage,
  effectChildren,
  value: currentValue,
  children,
  header = {},
  data = {},
  editMode = {},
  options = {},
  rowActions = {},
  appearance = {}
}: ViewModel.CyTableProps) => {
  const refreshId = `refresh-${id}`;
  const { ref, width } = useGetElementSize();
  const { useOnRefresh } = useContext(CyWrapperContext);
  const { deps } = useContext(GlobalContext);

  const { value, canLoad, isLoading, isFirstLoad, fetch, updateCursor } = useDataSource({
    pointer: componentName,
    data,
    currentValue
  });

  useOnRefresh({ id, componentName }, fetch);

  const { diffs, errors, list, properties, change, changeCount, editedRecords, reset } = useBulkEdit({ value });
  const { editing, toggleEditing, refresh } = useEditMode({ editMode, refreshId, fetch, reset });

  const { conflictingValues, handleSave, handleCancel, handleGoBack, handleOverrideItems } = useSaveBulk({
    data,
    editMode,
    items: editedRecords,
    refresh,
    changeCount: Object.keys(diffs).length
  });

  const onSave = useCallback(() => {
    if (!editMode.confirmationOnSave) return handleSave();
    deps.modalInteraction.onConfirm(
      {
        status: ViewModel.Status.WARN,
        icon: 'warning',
        title: 'Save changes',
        description: `You are about to save ${changeCount} ${changeCount === 1 ? 'change' : 'changes'}. Are you sure you want to continue?`,
        confirmLabel: 'Yes, save',
        cancelLabel: 'No, go back'
      },
      handleSave
    );
  }, [deps.modalInteraction, editMode.confirmationOnSave, handleSave, changeCount]);

  const onToggleEditing = useCallback(() => {
    const hasChanges = changeCount > 0;
    if (!hasChanges || !editing) return toggleEditing();
    deps.modalInteraction.onConfirm(
      {
        status: ViewModel.Status.WARN,
        icon: 'warning',
        title: 'Are you sure you want to cancel your table edits?',
        description: `Changes you've made will not be saved`,
        confirmLabel: 'Continue',
        cancelLabel: 'No, go back'
      },
      () => {
        toggleEditing();
        reset();
      }
    );
  }, [toggleEditing, reset, changeCount, editing, deps.modalInteraction]);

  const modalContainerContext = useContext(ModalContainerContext);
  const isModal = useMemo(() => modalContainerContext?.instance, [modalContainerContext?.instance]);

  return (
    <>
      <BBContainer framed={!isModal} fitToPage={fitToPage}>
        <ErrorBoundary>
          <div id={id} css={styles.container} data-testid={testId} ref={ref}>
            <div data-testid="effects">
              {effectChildren}
              <CyReusableActionEffect componentName={refreshId} id={refreshId} onPlay={fetch} />
            </div>
            <CyTableHeader
              refreshId={refreshId}
              value={value}
              query={value?.query}
              width={width}
              header={header}
              editMode={{
                enabled: editMode.enabled,
                editing,
                toggleEditing: onToggleEditing,
                onSave,
                changeCount
              }}
              data={{
                canLoad,
                isLoading,
                updateCursor,
                fetch
              }}
            />
            <EditBulkContext.Provider value={{ editedRecords, change, list }}>
              <CyTableView
                rowActions={rowActions}
                appearance={appearance}
                options={options}
                value={value}
                children={children}
                refreshId={refreshId}
                isLoading={isLoading}
                isFirstLoad={isFirstLoad}
                editing={editing}
                updateCursor={updateCursor}
                diffs={diffs}
                errors={errors}
                list={list}
                properties={properties}
                change={change}
                query={value?.query}
              />
            </EditBulkContext.Provider>
            <CyTablePagination
              updateCursor={updateCursor}
              disabled={isLoading || editing}
              hidden={options.hidePagination}
              cannotPaginate={!canLoad}
              value={value}
            />
          </div>
        </ErrorBoundary>
      </BBContainer>
      {conflictingValues && (
        <EditErrorModal onCancel={handleCancel} onGoBack={handleGoBack} onOverrideItems={handleOverrideItems} payload={conflictingValues} value={value} />
      )}
    </>
  );
};

CyTable.displayName = 'CyTable';
