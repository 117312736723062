import type { ComponentProps } from 'react';

import type { FlowModel } from '@cyferd/client-engine';
import { ViewModel, getClassnames } from '@cyferd/client-engine';

import { StepList } from './components';
import { styles } from './styles';
import { ListItem } from '@components/elements/List';
import { TRANS } from '@constants';

export interface FlowSidebarProps {
  flow: FlowModel.Flow;
  activeStepKey: string;
  onActiveStepChange: (stepKey: string) => void;
  onToggleAddModal: () => void;
  stepActions: any[];
}

const makeItem =
  ({ activeStepKey, onActiveStepChange, onToggleAddModal }) =>
  config => {
    const isActive = config.key === activeStepKey;
    return (
      <div key={config.key} className={styles.optionContainer}>
        <div className={getClassnames(styles.option, !!isActive && styles.activeOption)} data-testid={`${config.key}-btn`}>
          <ListItem
            isClickable={true}
            item={{ ...config, raw: {}, fullItem: {}, list: [] }}
            onClickItem={() => onActiveStepChange(config.key) as any}
            showDescription={false}
            optionMenuOverride={{ defaultBtnSize: ViewModel.CTASize.SMALL }}
            actionListChildren={
              [
                config.key !== 'steps' && {
                  important: true,
                  type: ViewModel.CTAType.ACTION_SECONDARY,
                  size: ViewModel.CTASize.SMALL,
                  icon: isActive ? 'close' : 'chevron_right',
                  onClick: () => onActiveStepChange(config.key) as any
                },
                config.key === 'steps' && {
                  important: true,
                  type: ViewModel.CTAType.ACTION,
                  size: ViewModel.CTASize.SMALL,
                  helperText: 'Add step',
                  icon: 'add',
                  onClick: () => onToggleAddModal()
                }
              ].filter(Boolean) as ComponentProps<typeof ListItem>['actionListChildren']
            }
          />
        </div>
      </div>
    );
  };

export const FlowSidebar = ({ flow, activeStepKey, onActiveStepChange, onToggleAddModal, stepActions }: FlowSidebarProps) => {
  return (
    <div>
      <div>
        <div className={styles.sidebarContainer}>
          {[
            {
              key: 'input',
              title: 'Input',
              color: 'BRAND_1',
              image: 'right_panel_open',
              description: 'Set the schema for the data that can be passed as parameters when this flow is run.'
            },
            { key: 'initial-step', title: TRANS.client.nav.builder.tabs.flows.start, color: 'BRAND_1', image: 'play_circle' },
            { key: 'steps', title: TRANS.client.nav.builder.tabs.flows.steps, color: 'BRAND_1', image: 'stairs' }
          ].map(makeItem({ activeStepKey, onActiveStepChange, onToggleAddModal }))}
          <div>
            <StepList activeStepKey={activeStepKey} flow={flow} onActiveStepChange={onActiveStepChange} stepActions={stepActions} />
          </div>
          {makeItem({ activeStepKey, onActiveStepChange, onToggleAddModal })({
            key: 'output',
            title: TRANS.client.nav.builder.tabs.flows.output,
            color: 'BRAND_1',
            image: 'right_panel_close',
            description: 'Set the result from the flow '
          })}
        </div>
      </div>
    </div>
  );
};
