import type { GeneralModel } from '@cyferd/client-engine';
import { ApiModel, ErrorBoundary, ViewModel, noop, prepareTermForReg, registry, useRegistry } from '@cyferd/client-engine';
import { CyList } from '@components/smart/CyList';
import type { ComponentProps } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Modal } from '../Modal';
import { SchemaForm } from '../../../builder/views/shared/SchemaForm';
import { EMPTY } from 'rxjs';
import { CTAType } from '../CTA';
import { collection } from './collection';
import { styles } from './styles';

export enum DevActionQuickFilterId {
  ERROR = 'err',
  SUCCESS = 'succ',
  NEUTRAL = 'neutral',
  FETCH = 'fetch',
  SAVE = 'save'
}

export const DevMenuActions = () => {
  const { actionRegistry } = useRegistry();
  const [activeActionId, setActiveActionId] = useState<string>(null);
  const [searchString, setSearchString] = useState<string>('');
  const [quickFilters, setQuickFilters] = useState<GeneralModel.FetchCriteria['quickFilters']>([]);
  const activeAction = useMemo(() => actionRegistry.find(reg => reg.id === activeActionId), [actionRegistry, activeActionId]);

  const value: ComponentProps<typeof CyList>['value'] = useMemo(
    () => ({
      query: {
        ...collection,
        cursor: { searchString, quickFilters },
        quickFilters: [
          { id: DevActionQuickFilterId.SUCCESS, name: 'Success', color: 'GN_2' },
          { id: DevActionQuickFilterId.NEUTRAL, name: 'Neutral', color: 'NEUTRAL_3' },
          { id: DevActionQuickFilterId.ERROR, name: 'Error', color: 'RD_2' },
          { id: DevActionQuickFilterId.FETCH, name: 'Fetch' },
          { id: DevActionQuickFilterId.SAVE, name: 'Save' }
        ]
      },
      totalCount: actionRegistry.length,
      list: actionRegistry.filter(reg => {
        const passesSearchString =
          !searchString ||
          [
            /** create deep search util */
            reg.action.trigger?.type,
            reg.action.trigger?.label,
            reg.request?.type,
            reg.request?.payload?.query?.cursor?.collectionId,
            reg.request?.payload?.searchString,
            reg.request?.payload?.path,
            reg.request?.payload?.pointer,
            reg.response?.type,
            reg.response?.payload?.query?.cursor?.collectionId,
            reg.response?.payload?.searchString,
            reg.response?.payload?.path,
            reg.response?.payload?.pointer,
            reg.telemetry?.context?.node?.name,
            reg.telemetry?.context?.node?.id,
            reg.telemetry?.context?.node?.component,
            reg.telemetry?.context?.node?.attrs?.type
          ].some(c => new RegExp(prepareTermForReg(searchString), 'i').test(c));
        /* istanbul ignore next line | @todo */
        const passesQuickFilters =
          !quickFilters?.length ||
          quickFilters.every(qf => {
            switch (qf.id) {
              case DevActionQuickFilterId.ERROR:
                return reg.response?.type?.startsWith('API');
              case DevActionQuickFilterId.SUCCESS:
                return !reg.response?.type?.startsWith('API') && reg.request?.type?.startsWith('CORE') && !!reg.response?.type;
              case DevActionQuickFilterId.NEUTRAL:
                return reg.request?.type?.startsWith('DISPATCH');
              case DevActionQuickFilterId.FETCH:
                return [ApiModel.FlowActionType.CORE_GET, ApiModel.FlowActionType.CORE_LIST, ApiModel.FlowActionType.CORE_DESCRIBE].includes(reg.request?.type);
              case DevActionQuickFilterId.SAVE:
                return [
                  ApiModel.FlowActionType.CORE_DELETE,
                  ApiModel.FlowActionType.CORE_UPDATE,
                  ApiModel.FlowActionType.CORE_CREATE,
                  ApiModel.FlowActionType.CORE_UPSERT
                ].includes(reg.request?.type);
              default:
                return true;
            }
          });
        return passesSearchString && passesQuickFilters;
      }) as any
    }),
    [actionRegistry, quickFilters, searchString]
  );

  const onClickItem: ViewModel.TriggerCallback<ApiModel.ApiRecord> = useCallback(event => {
    setActiveActionId(event.id);
    return EMPTY;
  }, []);

  const onClose = useCallback(() => {
    setActiveActionId(null);
    return EMPTY;
  }, []);

  const onFetch: ViewModel.CyListProps['onFetch'] = useCallback(event => {
    setSearchString(event.searchString);
    setQuickFilters(event.quickFilters);
    return EMPTY;
  }, []);

  const actionListChildren = useMemo(
    () =>
      [
        { helperText: 'See details', type: CTAType.ACTION_TERTIARY, important: true, icon: 'zoom_in', onClick: onClickItem }
      ] as ViewModel.CyListProps['actionListChildren'],
    [onClickItem]
  );

  const onClear = useCallback(() => {
    registry.clearActionRegistry();
    return EMPTY;
  }, []);

  const headerListChildren = useMemo(
    () => [{ label: 'Clear', type: CTAType.SECONDARY, important: true, onClick: onClear }] as ViewModel.CyListProps['headerListChildren'],
    [onClear]
  );

  return (
    <div data-testid="dev-menu-actions" css={styles.container}>
      <ErrorBoundary>
        <CyList
          value={value}
          fitToPage={true}
          type={ViewModel.CyListType.TABLE}
          density={ViewModel.Density.L}
          onClickItem={onClickItem}
          onFetch={onFetch}
          actionListChildren={actionListChildren}
          paginationHidden={true}
          orderHidden={true}
          recordActionsHidden={true}
          advancedFiltersHidden={true}
          framed={false}
          headerListChildren={headerListChildren}
        >
          <div />
        </CyList>
      </ErrorBoundary>
      {!!activeAction && (
        <Modal
          open={true}
          title={`${activeAction.action?.trigger?.label} ${activeAction.request?.type}`}
          type={ViewModel.ModalType.FULL_SCREEN}
          onClose={onClose}
        >
          <ErrorBoundary>
            <SchemaForm schema={collection.schema} detailGroupList={collection.detailGroupList} value={activeAction} onChange={noop} disabled={true} />
          </ErrorBoundary>
        </Modal>
      )}
    </div>
  );
};

DevMenuActions.displayName = 'DevMenuActions';
