import { forwardRef, useCallback, useContext, useState } from 'react';

import { Icon } from '@components/elements/Icon';
import { UIContext } from '@components/providers/UIprovider';
import { COLOR, FONT_SIZE, FOREGROUND_COLOR, HC_SECONDARY_COLOR, THEME } from '@constants';
import type { GeneralModel } from '@cyferd/client-engine';
import { styles } from './styles';

export interface BannerProps {
  active: boolean;
  position: 'top' | 'bottom';
  icon?: GeneralModel.IconName;
  text?: string;
  uncloseable?: boolean;
  bannerColorDark?: GeneralModel.Color.ThemeColor;
  bannerColorLight: GeneralModel.Color.ThemeColor;
}

export const Banner = forwardRef<HTMLDivElement, BannerProps>((props: BannerProps, ref) => {
  const { runtimeTheme } = useContext(UIContext);
  const { active, bannerColorDark, bannerColorLight, position, icon, text, uncloseable } = props;
  const [hide, setHide] = useState(false);
  const onClick = useCallback(() => setHide(true), []);

  if (!active || (!icon && !text) || hide) return <div ref={ref} data-testid="banner" />;

  const backgroundColor = runtimeTheme === THEME.DARK ? (bannerColorDark ?? bannerColorLight) : bannerColorLight;
  const textColor = FOREGROUND_COLOR[backgroundColor] ?? COLOR.HC_7;

  return (
    <div
      data-testid="banner"
      css={[styles.bannerContainer, position === 'bottom' ? styles.bottom : styles.top]}
      style={{ backgroundColor: COLOR[backgroundColor] ?? HC_SECONDARY_COLOR.HC_7 }}
      ref={ref}
    >
      {icon && <Icon name={icon} fill={textColor} size={FONT_SIZE.L} />}
      {text && <span style={{ color: textColor }}>{text}</span>}
      {!uncloseable && (
        <div css={styles.close}>
          <Icon name="close" size={FONT_SIZE.L} fill={textColor} onClick={onClick} />
        </div>
      )}
    </div>
  );
});
