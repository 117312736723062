import { styles } from './styles';
import { CTA } from '@components/elements/CTA';
import { ViewModel } from '@cyferd/client-engine';

export function ContextMenu({ menu: { event, node, actions }, setMenu, onClick: onDivClick }) {
  return (
    <div onClick={onDivClick} style={{ top: event.clientY, left: event.clientX }} className={styles.contextMenu}>
      {actions
        ?.filter(({ shouldHide }) => !shouldHide?.(node?.id, node?.data))
        .map(({ color, icon, label, onClick }) => (
          <CTA
            key={label}
            size={ViewModel.CTASize.SMALL}
            type={ViewModel.CTAType.ACTION}
            icon={icon}
            tooltip={label}
            color={color}
            onClick={() => {
              onClick(node?.id, node?.data);
              setMenu(null);
            }}
          />
        ))}
    </div>
  );
}
