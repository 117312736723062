import { useContext } from 'react';

import type { TenantConfigModel } from '@cyferd/client-engine';
import { ApiModel, ViewModel } from '@cyferd/client-engine';
import { useTestingHelper } from '@utils';

import { useGetCollectionDefinition } from '@utils/useGetCollectionDefinition';
import type { EditorContextValue } from '../../../shared/EditorHome';
import { EditorContext } from '../../../shared/EditorHome';
import { EmptyState } from '@components/elements/EmptyState';
import { SchemaForm } from '../../../shared/SchemaForm';
import { styles } from './styles';
import { Layout } from '@components/elements/Layout';
import { CyBlank } from '@components/smart/CyBlank';

export const GeneralInfo = () => {
  const { getTestIdProps } = useTestingHelper('general-info');
  const { item, setItem } = useContext<EditorContextValue<TenantConfigModel.TenantConfig>>(EditorContext);

  const collection = useGetCollectionDefinition(ApiModel.ApiEntity.TENANTCONFIG);

  if (!item) return <EmptyState />;

  return (
    <div {...getTestIdProps('container')} className={styles.container}>
      <Layout type={ViewModel.LayoutType.THREE_ALT_1} fitToPage={true}>
        <CyBlank />
        <SchemaForm
          maxColumns={2}
          schema={collection?.schema}
          value={item}
          onChange={setItem}
          detailGroupList={collection?.detailGroupList}
          wrapDetailGroups={true}
        />
      </Layout>
    </div>
  );
};
