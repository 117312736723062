import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';

import type { ViewModel } from '@cyferd/client-engine';
import { ApiModel, removeKeyList } from '@cyferd/client-engine';
import { useTestingHelper } from '@utils';

import { BUILDER_DESCRIPTION, BUILDER_ICON, BUILDER_ROUTE, QUERY_PARAM, SECTION_NAME, TRANS, getTempId } from '@constants';
import type { getNavigateToArgs } from '@utils/getNavigateToTab';
import { getNavigateToTab } from '@utils/getNavigateToTab';
import { useOnNavigateToItem } from '@utils/useOnNavigateToItem';
import { useRequest } from '@utils/useRequest';
import { BuilderCyList } from '../../../shared/BuilderCyList';
import type { EditorContextValue } from '../../../shared/EditorHome';
import { EditorContext } from '../../../shared/EditorHome';
import { TempHeader } from '../../../shared/TempHeader';
import { styles } from './styles';

export const GeneralList = () => {
  const { getTestIdProps } = useTestingHelper('general-list');
  const request = useRequest();
  const { setItem } = useContext<EditorContextValue<any>>(EditorContext);
  const { search } = useLocation();
  const navigate = useNavigate();

  const baseConfig: getNavigateToArgs = useMemo(
    () => ({
      id: null,
      path: null,
      root: BUILDER_ROUTE.INTEGRATION_EDITOR.ROOT,
      search,
      key: QUERY_PARAM.RECORD_ID,
      push: navigate
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onCreate = (meta: any) => {
    setItem({ name: 'New integration' });
    getNavigateToTab({ ...baseConfig, id: getTempId(), path: BUILDER_ROUTE.INTEGRATION_EDITOR.CHILDREN.GENERAL })(meta);
  };

  const onDuplicate = useCallback(
    (base: any) => {
      const item: any = removeKeyList({ ...base, name: `${base.name} copy` }, ['id', 'createdAt', 'updatedAt', 'createdBy', 'updatedBy', 'dsVersion']);
      getNavigateToTab({ ...baseConfig, id: getTempId(), path: BUILDER_ROUTE.INTEGRATION_EDITOR.CHILDREN.GENERAL })();
      setTimeout(() => setItem(item), 100); /** @todo this is a hack, find a better way of doing this */
    },
    [baseConfig, setItem]
  );

  const actionListChildren = useMemo(
    () =>
      [
        {
          icon: 'edit',
          label: TRANS.client.buttons.edit,
          important: true,
          onClick: (item, event) => getNavigateToTab({ ...baseConfig, id: item.id, path: BUILDER_ROUTE.INTEGRATION_EDITOR.CHILDREN.GENERAL })(event)
        },
        { icon: 'content_copy', label: TRANS.client.buttons.copy, onClick: onDuplicate }
      ] as ViewModel.CyListProps['actionListChildren'],
    [baseConfig, onDuplicate]
  );

  const onClickItem = useOnNavigateToItem(baseConfig, BUILDER_ROUTE.INTEGRATION_EDITOR.CHILDREN.GENERAL);

  useEffect(() => {
    setItem(undefined);
  }, [setItem]);

  return (
    <>
      <TempHeader
        title={SECTION_NAME.INTEGRATIONS}
        icon={BUILDER_ICON.INTEGRATIONS}
        subtitle={BUILDER_DESCRIPTION.INTEGRATIONS}
        actionListChildren={[{ label: TRANS.client.buttons.createNew, icon: 'add', onClick: onCreate as any, important: true }]}
      />
      <div {...getTestIdProps('container')} css={styles.container}>
        <BuilderCyList
          fitToPage={true}
          onClickItem={onClickItem}
          request={request}
          id={ApiModel.ApiEntity.INTEGRATION}
          componentName={ApiModel.ApiEntity.INTEGRATION}
          showDelete={true}
          key={ApiModel.ApiEntity.INTEGRATION}
          collectionId={ApiModel.ApiEntity.INTEGRATION}
          actionListChildren={actionListChildren}
        />
      </div>
    </>
  );
};
