import { CyLayout } from '@components/smart/CyLayout';
import { BuilderCyList } from '../../../builder/views/shared/BuilderCyList';
import { useRequest } from '@utils/useRequest';
import type { GeneralModel } from '@cyferd/client-engine';
import { ApiModel, ClientEngineContext, ViewModel } from '@cyferd/client-engine';
import { useCallback, useContext, useMemo } from 'react';
import { CyWrapperContext } from '@components/smart/CyWrapper';
import { TRANS } from '@constants';

export interface AppMenuProps {
  onClose: () => void;
}

export const AppMenu = ({ onClose }: AppMenuProps) => {
  const request = useRequest();
  const { useUserSelector } = useContext(ClientEngineContext);
  const { useAction } = useContext(CyWrapperContext);
  const user = useUserSelector();
  const onNavigateTo = useAction('dispatchNavigateTo');

  const appCriteria = useMemo(
    () =>
      ({
        collectionId: ApiModel.ApiEntity.TAG,
        options: { limit: 24 },
        filter: { hasAccess: { $matches: { id: user?.id } }, hideApp: { $ne: true } }
      }) as GeneralModel.FetchCriteria,
    [user?.id]
  );

  const onNavigateToApps = useCallback(
    (event: ApiModel.ApiRecord, meta: any) => {
      onClose();
      return onNavigateTo({ path: '/APP_HOME', qs: { id: event?.id } }, meta);
    },
    [onClose, onNavigateTo]
  );

  return (
    <div data-testid="apps-tab">
      <CyLayout itemHeight={ViewModel.LayoutHeightPreset.REMAINING}>
        <BuilderCyList
          request={request}
          framed={false}
          title={TRANS.client.nav.apps}
          collectionId={ApiModel.ApiEntity.TAG}
          initialFetchCriteria={appCriteria}
          type={ViewModel.CyListType.GRID}
          onClickItem={onNavigateToApps}
          quickFiltersHidden={true}
          typeSelectorHidden={true}
          advancedFiltersHidden={true}
        />
      </CyLayout>
    </div>
  );
};

AppMenu.displayName = 'AppMenu';
