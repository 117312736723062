import { useContext, useMemo } from 'react';

import type { TagModel } from '@cyferd/client-engine';
import { ApiModel, noop } from '@cyferd/client-engine';

import { BondEditor } from '../../../shared/BondEditor';
import type { EditorContextValue } from '../../../shared/EditorHome';
import { EditorContext } from '../../../shared/EditorHome';
import { EmptyLinkingPage } from '../../../../../@components/elements/EmptyLinkingPage';
import { LinkingPageDiagram, PredefinedDiagrams } from '../../../../../@components/elements/LinkingPageDiagram';

export const IntegrationBondEditor = () => {
  const { item } = useContext<EditorContextValue<TagModel.Tag>>(EditorContext);

  const cursor = useMemo(() => ({ associationKey: 'appIntegrations', relatedTo: { collectionId: ApiModel.ApiEntity.TAG, id: item?.id } }), [item]);

  return (
    <BondEditor
      item={item}
      cursor={cursor}
      fitToPage={true}
      collectionId={ApiModel.ApiEntity.INTEGRATION}
      associationKey="appIntegrations"
      modalTitle="Unlinked integrations"
      customEmptyState={
        <EmptyLinkingPage
          title="Nothing linked"
          subtitle="Connections of data, applications, APIs, and devices"
          ctaLabel="Link integration"
          ctaOnClick={noop}
          diagram={<LinkingPageDiagram value={PredefinedDiagrams.appLinkedIntegrations} />}
        />
      }
    />
  );
};
