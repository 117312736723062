import { useCallback, useContext, useMemo } from 'react';

import { ApiModel, ViewModel } from '@cyferd/client-engine';
import { useTestingHelper } from '@utils';

import { EmptyState } from '@components/elements/EmptyState';
import { Layout } from '@components/elements/Layout';
import { useGetCollectionDefinition } from '@utils/useGetCollectionDefinition';
import type { EditorContextValue } from '../../../shared/EditorHome';
import { EditorContext } from '../../../shared/EditorHome';
import { SchemaForm } from '../../../shared/SchemaForm';
import { styles } from './styles';

export const GeneralInfo = () => {
  const { getTestIdProps } = useTestingHelper('general-info');
  const { item, setItem } = useContext<EditorContextValue<any>>(EditorContext);

  const onChange = useCallback((entity: ApiModel.ApiEntity.ENTITY) => setItem(entity), [setItem]);

  const collection = useGetCollectionDefinition(ApiModel.ApiEntity.INTEGRATION);
  const cursor = useMemo(() => ({ collectionId: ApiModel.ApiEntity.INTEGRATION, id: item?.id }), [item?.id]);

  if (!item || !collection) return <EmptyState />;

  return (
    <div {...getTestIdProps('container')} className={styles.container}>
      <Layout itemHeight={ViewModel.LayoutHeightPreset.REMAINING}>
        <SchemaForm
          schema={collection?.schema}
          cursor={cursor}
          value={item}
          onChange={onChange}
          wrapDetailGroups={true}
          detailGroupList={collection?.detailGroupList}
        />
      </Layout>
    </div>
  );
};
