import { useContext } from 'react';

import { ViewModel, type CollectionModel } from '@cyferd/client-engine';

import { EmptyState } from '@components/elements/EmptyState';
import { HasFlagRenderer } from '@components/elements/HasFlagRenderer';
import { FLAGS } from '@constants';
import { userInputList } from '@models/user';
import { EditorContext, type EditorContextValue } from '../../../shared/EditorHome';
import { SchemaForm } from '../../../shared/SchemaForm';
import { PreviewLayout } from '../PreviewLayout/PreviewLayout';
import { LegacyFilterEditor } from './LegacyFilterEditor';
import { detailGroupList } from './schemas';
import { useFilterEditorSchema } from './useFilterEditorSchema';
import { Layout } from '@components/elements/Layout';

export const FilterEditor = () => <HasFlagRenderer NewComponent={NewFilterEditor} LegacyComponent={LegacyFilterEditor} flag={FLAGS.EARLY_ACCESS} />;

export const NewFilterEditor = () => {
  const { item, setItem } = useContext<EditorContextValue<CollectionModel.Collection>>(EditorContext);
  const schema = useFilterEditorSchema(item);

  if (!item) return <EmptyState />;

  return (
    <Layout itemHeight={ViewModel.LayoutHeightPreset.REMAINING}>
      <PreviewLayout collection={item} onCollectionChange={setItem}>
        <SchemaForm onChange={setItem} schema={schema} detailGroupList={detailGroupList} value={item} wrapDetailGroups={true} inputList={userInputList} />
      </PreviewLayout>
    </Layout>
  );
};
