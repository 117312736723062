import { useContext, useMemo } from 'react';

import type { TagModel } from '@cyferd/client-engine';
import { ApiModel, noop } from '@cyferd/client-engine';

import { BondEditor } from '../../../shared/BondEditor';
import type { EditorContextValue } from '../../../shared/EditorHome';
import { EditorContext } from '../../../shared/EditorHome';
import { EmptyLinkingPage } from '@components/elements/EmptyLinkingPage';
import { LinkingPageDiagram, PredefinedDiagrams } from '@components/elements/LinkingPageDiagram';

export const FlowBondEditor = () => {
  const { item } = useContext<EditorContextValue<TagModel.Tag>>(EditorContext);

  const cursor = useMemo(() => ({ associationKey: 'appFlows', relatedTo: { collectionId: ApiModel.ApiEntity.TAG, id: item?.id } }), [item]);

  return (
    <BondEditor
      item={item}
      cursor={cursor}
      fitToPage={true}
      collectionId={ApiModel.ApiEntity.FLOW}
      associationKey="appFlows"
      modalTitle="Unlinked flows"
      customEmptyState={
        <EmptyLinkingPage
          title="Nothing linked"
          subtitle="Flows are custom processes that can run manually, on schedule, or be triggered by other actions"
          ctaLabel="Link flow"
          ctaOnClick={noop}
          diagram={<LinkingPageDiagram value={PredefinedDiagrams.appLinkedFlows} />}
        />
      }
    />
  );
};
