import { memo, useCallback, useContext, useMemo, useState } from 'react';

import { ClientEngineContext, GeneralModel, ViewModel } from '@cyferd/client-engine';

import { AccountMenu } from '@components/elements/AccountMenu';
import { Avatar } from '@components/elements/Avatar';
import { CTA, CTAType } from '@components/elements/CTA';
import { FavoriteMenu } from '@components/elements/FavoriteMenu';
import { NotificationMenu } from '@components/elements/NotificationMenu';
import { OptionMenu } from '@components/elements/OptionMenu';
import { PreventClickPropagation } from '@components/elements/PreventClickPropagation';
import { Sidebar } from '@components/elements/Sidebar';
import { ToolTip } from '@components/elements/Tooltip';
import { UIContext } from '@components/providers/UIprovider';
import { TRANS, getIsDynamicPage } from '@constants';
import { useOutsideClick } from '@utils/useOutsideClick';
import { useUrlBuilder } from '@utils/useUrlBuilder';
import { useFavorite } from '../../../../../client-app/state-mgmt/favorite';
import { useNotificationSelector } from '../../../../../client-app/state-mgmt/notification';
import { styles } from './styles';
// move to components folder
import { usePrint } from '../../Print';
import { useShortLink } from '../../Shortlink';

export enum GlobalToolName {
  ACCOUNT = 'ACCOUNT',
  NOTIFICATION = 'NOTIFICATION',
  APP = 'APP',
  FAVORITE = 'FAVORITE',
  DEVTOOL = 'DEVTOOL'
}

export interface GlobalToolbarProps {
  hiddenFavorites?: boolean;
  hiddenNotifications?: boolean;
  hiddenPreferences?: boolean;
  hiddenShortlink?: boolean;
  hiddenPrint?: boolean;
  verticalOffset?: number;
  verticalOffsetDirection?: 'top' | 'bottom';
}

export const GlobalToolbar = memo(
  ({ hiddenFavorites, hiddenNotifications, hiddenPreferences, hiddenShortlink, hiddenPrint, verticalOffset, verticalOffsetDirection }: GlobalToolbarProps) => {
    const { useUserSelector } = useContext(ClientEngineContext);
    const favState = useFavorite();
    const user = useUserSelector();
    const [toolOpen, setToolOpen] = useState<GlobalToolName>(null);
    const { pathname } = useUrlBuilder();
    const isDynamicPage = getIsDynamicPage(pathname);
    const notification = useNotificationSelector();
    const hasUnreadNotifications = useMemo(() => !!notification?.latest?.list?.find(notification => notification?.read === false), [notification?.latest]);
    const { size } = useContext(UIContext);
    const onClose = () => setToolOpen(null);
    const onChangeToolbar = useCallback((event: GlobalToolName) => () => setToolOpen(prev => (prev === event ? null : event)), []);
    const outsideRef = useOutsideClick(onClose);

    const { onClick: onLink, ShortLinkModal } = useShortLink();
    const { onClick: onPrint, printStyle } = usePrint();

    const optionList = useMemo(
      () =>
        [
          {
            hidden: hiddenNotifications || size !== GeneralModel.AppSize.S,
            label: TRANS.client.nav.notifs,
            image: 'notifications' as GeneralModel.IconName,
            color: 'NEUTRAL_1' as GeneralModel.Color.ThemeColor,
            outlined: true,
            onClick: onChangeToolbar(GlobalToolName.NOTIFICATION),
            dot: hasUnreadNotifications
          },
          {
            hidden: hiddenFavorites || size !== GeneralModel.AppSize.S,
            label: TRANS.client.nav.favs,
            image: 'star' as GeneralModel.IconName,
            color: 'NEUTRAL_1' as GeneralModel.Color.ThemeColor,
            outlined: true,
            onClick: onChangeToolbar(GlobalToolName.FAVORITE)
          },
          {
            hidden: hiddenShortlink || size === GeneralModel.AppSize.L,
            label: TRANS.client.nav.share,
            image: 'share' as GeneralModel.IconName,
            color: 'NEUTRAL_1' as GeneralModel.Color.ThemeColor,
            outlined: true,
            onClick: onLink
          },
          {
            hidden: hiddenPrint || size === GeneralModel.AppSize.L,
            label: TRANS.client.nav.print,
            image: 'print' as GeneralModel.IconName,
            color: 'NEUTRAL_1' as GeneralModel.Color.ThemeColor,
            outlined: true,
            onClick: onPrint
          }
        ].filter(opt => !opt.hidden),
      [onChangeToolbar, onPrint, onLink, hasUnreadNotifications, hiddenNotifications, size, hiddenPrint, hiddenShortlink, hiddenFavorites]
    );

    if ([hiddenFavorites, hiddenNotifications, hiddenPreferences, hiddenShortlink, hiddenPrint].every(Boolean)) return null;

    return (
      <div ref={outsideRef} data-testid="GlobalToolbar">
        <div data-testid="toolbar" css={styles.toolbar}>
          {!hiddenFavorites && size !== GeneralModel.AppSize.S && (
            <CTA
              testid="favorites-toggle"
              icon="star"
              outlined={!isDynamicPage || !favState?.current}
              type={CTAType.LINK}
              size={ViewModel.CTASize.LARGE}
              color="NEUTRAL_1"
              tooltip={TRANS.client.nav.favs}
              onClick={onChangeToolbar(GlobalToolName.FAVORITE)}
            />
          )}
          {!hiddenNotifications && size !== GeneralModel.AppSize.S && (
            <div css={styles.relativeContainer}>
              <CTA
                testid="notifications-toggle"
                icon="notifications"
                outlined={true}
                type={CTAType.LINK}
                size={ViewModel.CTASize.LARGE}
                color="NEUTRAL_1"
                tooltip={TRANS.client.nav.notifs}
                onClick={onChangeToolbar(GlobalToolName.NOTIFICATION)}
              />
              {hasUnreadNotifications && <div data-testid="unread-flag" css={styles.dot} />}
            </div>
          )}
          {!hiddenPreferences && (
            <ToolTip text={TRANS.client.nav.account}>
              <div css={styles.avatar} onClick={onChangeToolbar(GlobalToolName.ACCOUNT)} data-testid="account-toggle">
                <Avatar size="fill" src={user?.recordImage} alt={user?.fullName} />
              </div>
            </ToolTip>
          )}
          <div css={styles.relativeContainer}>
            {hasUnreadNotifications && !hiddenNotifications && size === GeneralModel.AppSize.S && <div data-testid="unread-flag" css={styles.dot} />}
            {optionList.length > 0 && (
              <OptionMenu defaultBtnType={CTAType.LINK} defaultBtnColor="NEUTRAL_1" defaultBtnSize={ViewModel.CTASize.LARGE} optionList={optionList} />
            )}
          </div>
        </div>
        <Sidebar open={!!toolOpen} verticalOffset={verticalOffset} verticalOffsetDirection={verticalOffsetDirection}>
          <PreventClickPropagation containerCss={styles.prevent}>
            {toolOpen === GlobalToolName.FAVORITE && <FavoriteMenu onClose={onClose} />}
            {toolOpen === GlobalToolName.ACCOUNT && <AccountMenu onClose={onClose} />}
            {toolOpen === GlobalToolName.NOTIFICATION && <NotificationMenu />}
          </PreventClickPropagation>
        </Sidebar>
        <ShortLinkModal />
        <div css={printStyle} />
      </div>
    );
  }
);

GlobalToolbar.displayName = 'GlobalToolbar';
