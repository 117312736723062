import { useCallback, useContext, useMemo, useState } from 'react';
import { EMPTY, takeUntil } from 'rxjs';

import { CTA, CTAType } from '@components/elements/CTA';
import type { ApiModel, CollectionModel, GeneralModel, TagModel, UserModel } from '@cyferd/client-engine';
import { ViewModel, actions, createUUID, tapOnSuccess, useUnmountObservable } from '@cyferd/client-engine';
import { useTestingHelper } from '@utils';

import { EmptyState } from '@components/elements/EmptyState';
import { Modal } from '@components/elements/Modal';
import { ENV } from '@constants';
import { getLink } from '@utils/getLink';
import { useOnOpenExternalUrl } from '@utils/useOnOpenExternalUrl';
import { useRequest } from '@utils/useRequest';
import React from 'react';
import { GlobalContext } from '../../../state-mgmt/GlobalState';
import { BuilderCyList } from '../BuilderCyList';
import { styles } from './styles';
import { Layout } from '@components/elements/Layout';

export type Item = TagModel.Tag | CollectionModel.Collection | UserModel.User | ViewModel.View;

export interface AdditionalAction extends Omit<ViewModel.CyListProps['actionListChildren'][0], 'onClick'> {
  onClick: (event: ApiModel.ApiRecord | any) => any;
}

export type Props = {
  componentId?: string;
  item: Item | ApiModel.ApiRecord;
  collectionId: ApiModel.ApiEntity;
  additionalActions?: AdditionalAction[];
  cursor: GeneralModel.FetchCriteria;
  overrideGetLink?: (item: ApiModel.ApiRecord) => string;
  modalTitle?: string;
  associationKey?: string;
  searchStringHidden?: boolean;
  advancedFiltersHidden?: boolean;
  hideLinkingModalBtn?: boolean;
  hideActionListChildren?: boolean;
  customEmptyState?: React.ReactElement;
  fitToPage?: boolean;
};

export const BondEditor = ({
  componentId,
  item,
  collectionId,
  additionalActions = [],
  cursor,
  associationKey,
  searchStringHidden,
  advancedFiltersHidden,
  hideLinkingModalBtn,
  hideActionListChildren,
  overrideGetLink,
  modalTitle,
  customEmptyState,
  fitToPage
}: Props) => {
  const id = useMemo(() => componentId ?? createUUID(), [componentId]);
  const { getTestIdProps } = useTestingHelper('bond-editor');
  const [isLinkMoreOpen, setLinkMoreOpen] = useState<boolean>(false);
  const request = useRequest();
  const onDestroy$ = useUnmountObservable();
  const { deps } = useContext(GlobalContext);

  const onToggleModal = useCallback(() => {
    setLinkMoreOpen(prev => !prev);
    return EMPTY;
  }, []);

  const linkChangeEvent = useCallback(
    (event: ApiModel.ApiRecord) => {
      const isLinked = event?.$r?.id;
      return request(
        actions.coreUpsert({
          $cyf_escape: [
            {
              query: { cursor: { id: item.id, collectionId: item.collectionId } },
              record: {
                [`$$${associationKey}`]: {
                  add: [!isLinked && { id: event.id }].filter(Boolean),
                  remove: [isLinked && { id: event.id, $r: { id: event?.$r?.id } }].filter(Boolean)
                }
              },
              options: { reset: false }
            }
          ]
        } as any)
      ).pipe(
        takeUntil(onDestroy$),
        tapOnSuccess(() => deps.refresh$.next(id))
      );
    },
    [associationKey, deps.refresh$, id, item?.collectionId, item?.id, onDestroy$, request]
  );

  const onUnlink = useCallback(
    (event: ApiModel.ApiRecord) => {
      deps.modalInteraction.onConfirm(
        {
          status: ViewModel.Status.INFO,
          icon: 'link_off',
          title: `Unlink "${event?.recordTitle}"?`,
          description: '',
          confirmLabel: 'Unlink'
        },
        () => linkChangeEvent(event).subscribe()
      );
      return EMPTY;
    },
    [deps.modalInteraction, linkChangeEvent]
  );

  const onLinkChange = useCallback(
    (event: ApiModel.ApiRecord) => {
      const isLinked = !!event?.$r?.id;

      if (isLinked) {
        return onUnlink(event);
      }

      return linkChangeEvent(event);
    },
    [linkChangeEvent, onUnlink]
  );

  const openExternalUrl = useOnOpenExternalUrl();

  if (!item?.id) return <EmptyState />;

  const renderTable = (isLinkingList: boolean) => (
    <BuilderCyList
      componentName={id}
      request={request}
      fitToPage={fitToPage}
      type={ViewModel.CyListType.TABLE}
      searchStringHidden={searchStringHidden}
      advancedFiltersHidden={advancedFiltersHidden}
      initialFetchCriteria={{ ...cursor, options: { limit: 25 }, excluded: isLinkingList }}
      onClickItem={event => openExternalUrl(`${ENV.PUBLIC_URL}${overrideGetLink ? overrideGetLink(event) : getLink(event?.id, collectionId)}`)}
      actionListChildren={
        !hideActionListChildren && [
          { icon: isLinkingList ? 'link' : 'link_off', label: isLinkingList ? 'Link' : 'Unlink', onClick: onLinkChange as any, important: true },
          ...(!!isLinkingList ? [] : additionalActions)
        ]
      }
      headerListChildren={[
        !hideLinkingModalBtn && !isLinkingList && { important: true, label: 'Link more', type: CTAType.SECONDARY, onClick: onToggleModal }
      ].filter(Boolean)}
      customEmptyState={
        !!customEmptyState &&
        !isLinkingList &&
        React.cloneElement(customEmptyState, {
          ctaOnClick: onToggleModal
        })
      }
    />
  );

  return (
    <>
      {!!cursor && (
        <Modal
          type={ViewModel.ModalType.FULL_SCREEN}
          open={isLinkMoreOpen}
          onClose={onToggleModal}
          title={modalTitle || 'Unlinked items'}
          footer={<CTA type={CTAType.SECONDARY} label="OK" onClick={onToggleModal} testid="close-button" />}
        >
          <Layout fitToPage={true}>{renderTable(true)}</Layout>
        </Modal>
      )}
      <div {...getTestIdProps('container')} className={styles.container}>
        {renderTable(false)}
      </div>
    </>
  );
};
