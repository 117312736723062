import { CONTAINER_APP_PADDING } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    ${CONTAINER_APP_PADDING}
  `
};
