import { useMemo } from 'react';

import { ApiModel, createUUID } from '@cyferd/client-engine';

import { BUILDER_DESCRIPTION, BUILDER_ICON, SECTION_NAME } from '@constants';
import { useTestingHelper } from '@utils';
import { useRequest } from '@utils/useRequest';
import { BuilderCyList } from '../../../shared/BuilderCyList';
import { TempHeader } from '../../../shared/TempHeader';
import { styles } from './styles';

export const GeneralList = () => {
  const id = useMemo(createUUID, []);
  const { getTestIdProps } = useTestingHelper('general-list');
  const request = useRequest();

  return (
    <>
      <TempHeader title={SECTION_NAME.DEVICE_MANAGER} icon={BUILDER_ICON.DEVICE_MANAGER} subtitle={BUILDER_DESCRIPTION.DEVICE_MANAGER} />
      <div {...getTestIdProps('container')} css={styles.container}>
        <BuilderCyList
          fitToPage={true}
          componentName={id}
          key={ApiModel.ApiEntity.DEVICE}
          showDelete={true}
          request={request}
          collectionId={ApiModel.ApiEntity.DEVICE}
        />
      </div>
    </>
  );
};
