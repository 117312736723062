import { CyLayout } from '@components/smart/CyLayout';
import type { CyListContextValue } from '@components/smart/CyList';
import { CyListContext } from '@components/smart/CyList';
import { BuilderCyList } from '../../../builder/views/shared/BuilderCyList';
import { useRequest } from '@utils/useRequest';
import type { FavoriteModel } from '@cyferd/client-engine';
import { ApiModel, ClientEngineContext, GeneralModel, ViewModel, createUUID, swallowError, useFinalizeWhileMounted } from '@cyferd/client-engine';
import { useCallback, useContext, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { CyWrapperContext } from '@components/smart/CyWrapper';
import { Header } from '../Header';
import { styles } from './styles';
import { CTA, CTAType } from '@components/elements/CTA';
import { useUrlBuilder } from '@utils/useUrlBuilder';
import { concat, mergeMap, of, tap } from 'rxjs';
import { TRANS, getIsDynamicPage } from '@constants';
import { Input } from '../Input';
import { GlobalMenuHeader } from '../GlobalMenuHeader';

export interface FavoriteMenuProps {
  onClose: () => void;
}

export const FavoriteMenu = ({ onClose }: FavoriteMenuProps) => {
  const request = useRequest();
  const { useViewSelector } = useContext(ClientEngineContext);
  const { useAction } = useContext(CyWrapperContext);
  const onNavigateTo = useAction('dispatchNavigateTo');
  const controlsRef = useRef<HTMLDivElement>();
  const [controlsContainer, setControlsContainer] = useState<HTMLDivElement>();
  const { pathname, queryParams } = useUrlBuilder();
  const onUpsert = useAction('coreUpsert');
  const onDelete = useAction('coreDelete');
  const dispatchRefresh = useAction('dispatchRefresh');
  const view = useViewSelector();
  const listId = useMemo(() => createUUID(), []);
  const [selectedFav, setSelectedFav] = useState<FavoriteModel.Favorite>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const finalize = useFinalizeWhileMounted();
  const isDynamicPage = getIsDynamicPage(pathname);

  const onGoToCreateNew = () => setSelectedFav({ name: view?.name, view: { id: view?.id, input: queryParams }, user: undefined });
  const onClear = () => setSelectedFav(null);

  const onUpsertFav = (event: FavoriteModel.Favorite) => {
    setLoading(true);
    return concat(
      onUpsert({
        query: { cursor: { collectionId: ApiModel.ApiEntity.FAVORITE, id: event.id } },
        record: event,
        pointer: GeneralModel.IGNORED_POINTER_ID
      }),
      of(null)
        .pipe(
          tap(onClear),
          mergeMap(() => dispatchRefresh({ componentNameList: [listId] }))
        )
        .pipe(
          swallowError(),
          finalize(() => setLoading(false))
        )
    );
  };

  const onDeleteFav = useCallback(
    (event: ApiModel.ApiRecord) =>
      concat(
        onDelete({
          query: { cursor: { collectionId: ApiModel.ApiEntity.FAVORITE, id: event?.id } },
          pointer: GeneralModel.IGNORED_POINTER_ID
        }),
        of(null).pipe(
          swallowError(),
          mergeMap(() => dispatchRefresh({ componentNameList: [listId] }))
        )
      ),
    [listId, onDelete, dispatchRefresh]
  );

  const onNavigateToApps = useCallback(
    (event: ApiModel.ApiRecord, meta: any) => {
      onClose();
      return onNavigateTo({ path: event?.view?.id, qs: event?.view?.input }, meta);
    },
    [onClose, onNavigateTo]
  );

  const actionListChildren: ViewModel.CyListProps['actionListChildren'] = useMemo(
    () => [
      { important: false, label: TRANS.client.buttons.edit, icon: 'edit', type: CTAType.LINK, onClick: event => setSelectedFav(event) as any },
      { important: false, label: TRANS.client.buttons.delete, color: 'RD_2', icon: 'delete', type: CTAType.LINK, onClick: onDeleteFav as any }
    ],
    [onDeleteFav]
  );

  const cyListContextValue: CyListContextValue = useMemo(() => ({ controlsPortalRef: controlsContainer }), [controlsContainer]);

  useLayoutEffect(() => {
    setControlsContainer(controlsRef.current);
  }, []);

  return (
    <div data-testid="favorites-tab">
      <CyLayout itemHeight={ViewModel.LayoutHeightPreset.REMAINING}>
        {!selectedFav && (
          <CyListContext.Provider value={cyListContextValue}>
            <Header
              title={TRANS.client.nav.favs}
              controls={
                <div css={styles.controls}>
                  <div ref={controlsRef} />
                  {isDynamicPage && (
                    <CTA
                      testid="add"
                      type={CTAType.LINK}
                      size={ViewModel.CTASize.LARGE}
                      icon="add"
                      tooltip="Add this page to favorites"
                      onClick={onGoToCreateNew}
                    />
                  )}
                </div>
              }
            />
            <BuilderCyList
              componentName={listId}
              request={request}
              framed={false}
              collectionId={ApiModel.ApiEntity.FAVORITE}
              type={ViewModel.CyListType.LIST}
              onClickItem={onNavigateToApps}
              actionListChildren={actionListChildren}
              quickFiltersHidden={true}
              typeSelectorHidden={true}
              advancedFiltersHidden={true}
            />
          </CyListContext.Provider>
        )}
        {!!selectedFav && <FavEditor value={selectedFav} disabled={loading} onChange={onUpsertFav} onCancel={onClear} />}
      </CyLayout>
    </div>
  );
};

FavoriteMenu.displayName = 'FavoriteMenu';

const FavEditor = ({
  value,
  disabled,
  onChange,
  onCancel
}: {
  value: FavoriteModel.Favorite;
  disabled: boolean;
  onChange: (event: FavoriteModel.Favorite) => void;
  onCancel: () => void;
}) => {
  const [state, setState] = useState<FavoriteModel.Favorite>(value);

  return (
    <div css={styles.editionContainer}>
      <GlobalMenuHeader title={value.id ? value.name : 'New favorite'} onBack={onCancel} />
      <Input label={value.id ? 'Rename' : 'Name'} onChange={event => setState(p => ({ ...p, name: event }))} value={state.name} />
      <div css={styles.editionSubmit}>
        <CTA
          testid="save"
          type={CTAType.PRIMARY}
          size={ViewModel.CTASize.SMALL}
          label={TRANS.client.buttons.save}
          onClick={() => onChange(state)}
          disabled={disabled || (!!value.id && state.name === value.name)}
        />
      </div>
    </div>
  );
};
