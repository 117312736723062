import type { MouseEvent } from 'react';
import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ViewModel, noop, useTimeout } from '@cyferd/client-engine';

import { CTA, CTAType } from '@components/elements/CTA';
import { Icon } from '@components/elements/Icon';
import { JSONSyntaxEditor } from '@components/elements/JSONSyntaxEditor';
import { Modal } from '@components/elements/Modal';
import type { ToastProps } from '@components/elements/Toast';
import { Toast, ToastStatus } from '@components/elements/Toast';
import { CyWrapperContext } from '@components/smart/CyWrapper/CyWrapper';
import { COLOR, ENV, MODAL_ID, TRANS } from '@constants';
import type { GeneralModel } from '@models';
import { createPortal } from 'react-dom';
import { actions as uiActions } from '../../state-mgmt/ui/actions';
import { styles } from './styles';

export const ToastList = memo(() => {
  const dispatch = useDispatch();
  const dismissList = useRef<string[]>([]);
  const timeoutRunner = useTimeout();
  const [selectedToast, setSelectedToast] = useState<GeneralModel.State['ui']['toastList'][0]>(null);
  const { useAction } = useContext(CyWrapperContext);
  const onNavigateTo = useAction('dispatchNavigateTo');

  const maxToasts = 3;
  const { toastList } = useSelector((state: GeneralModel.State) => state.ui);
  const visibleToastList = useMemo(() => toastList.slice(0, maxToasts), [toastList]);

  const onClose = useCallback((id: string) => dispatch(uiActions.removeToast(id)), [dispatch]);

  const openModal = (event: MouseEvent<HTMLButtonElement, MouseEvent>, toastItem: GeneralModel.State['ui']['toastList'][0]) => {
    event?.stopPropagation?.();
    setSelectedToast(toastItem);
  };

  const onCloseModal = () => {
    setSelectedToast(null);
  };

  useEffect(() => {
    if (selectedToast) return;
    const unsetList = [toastList[0]]
      .filter(Boolean)
      .filter(({ toast }) => !dismissList.current.includes(toast.id))
      .map(({ toast }) => toast.id);
    dismissList.current = [...dismissList.current, ...unsetList];
    unsetList.forEach(id => timeoutRunner(() => onClose(id), ENV.TOAST_TIME));
  }, [toastList, onClose, timeoutRunner, selectedToast]);

  const toadListContent = (
    <div data-testid="toast-list-container" className={styles.container}>
      {visibleToastList.reverse().map(props => (
        <div key={props.toast.id} className={styles.item}>
          <Toast
            status={ToastStatus.INFO}
            {...(props.toast as ToastProps)}
            onClose={onClose}
            message={
              !!props.content && props.toast.status === ToastStatus.ERROR && !!(props.toast as ToastProps)?.message && (props.toast as ToastProps)?.message
            }
            ctaProps={(() => {
              if (!!props.content && props.toast.status === ToastStatus.SUCCESS)
                return {
                  type: CTAType.LINK,
                  size: ViewModel.CTASize.SMALL,
                  label: 'View',
                  icon: 'keyboard_arrow_right',
                  testid: 'toast-navigate-btn',
                  onClick: event => onNavigateTo({ path: 'DETAIL', qs: props.content }, event)
                };

              if (!!props.content && props.toast.status === ToastStatus.ERROR)
                return {
                  keepNativeEvent: true,
                  type: CTAType.LINK,
                  size: ViewModel.CTASize.SMALL,
                  label: 'more info',
                  onClick: event => openModal(event, props),
                  testid: 'toast-more-info-btn'
                };
            })()}
          />
        </div>
      ))}
      <Modal
        open={!!selectedToast}
        title={
          <span className={styles.modalTitle}>
            <Icon size="40px" fill={COLOR.RD_3} name="cancel" />
            <span>
              More info
              <p className={styles.modalSubtitle}>Contact your administrator with this data</p>
            </span>
          </span>
        }
        onClose={onCloseModal}
        footer={<CTA type={CTAType.PRIMARY} label={TRANS.client.buttons.close} onClick={onCloseModal} />}
      >
        <JSONSyntaxEditor expanded={true} avoidExpandOption={true} label="" height={300} disabled={true} value={selectedToast?.content} onChange={noop} />
      </Modal>
    </div>
  );

  if (MODAL_ID) {
    const portalNode = document.getElementById(MODAL_ID);
    if (portalNode) {
      return createPortal(toadListContent, portalNode);
    }
  }
  return toadListContent;
});
