import { useFeatureFlag } from '@utils';
import { Preview as PreviewNew } from './New';
import { Preview as PreviewLegacy } from './Legacy';
import { FLAGS } from '@constants';

export const Preview = () => {
  const hasEarlyAccess = useFeatureFlag({ flag: FLAGS.EARLY_ACCESS });

  if (hasEarlyAccess) return <PreviewNew />;
  return <PreviewLegacy />;
};
