import type { ComponentProps } from 'react';

import type { FlowModel } from '@cyferd/client-engine';
import { ViewModel, getClassnames } from '@cyferd/client-engine';

import type { TabConfig } from '../../resources';
import { listItemBase, tabConfigMap } from '../../resources';
import { StepList } from '../StepList';
import { styles } from './styles';
import { ListItem } from '@components/elements/List';

const getListItemConfig = ({ title, image, description, color }: TabConfig): ComponentProps<typeof ListItem>['item'] => ({
  title,
  image,
  description,
  color,
  ...listItemBase
});

export interface FlowSidebarProps {
  flow: FlowModel.Flow;
  activeTab: string;
  activeStepKey: string;
  onTabChange: (event: string) => void;
  onActiveStepChange: (stepKey: string, tabKey: string) => void;
  onAdd: () => void;
  onRemove: (stepKey: string) => void;
}

export const FlowSidebar = ({ flow, activeTab, activeStepKey, onTabChange, onActiveStepChange, onAdd, onRemove }: FlowSidebarProps) => {
  const getOnToggleTab = (tab: string) => () => onTabChange(tab) as any;

  return (
    <div>
      <div>
        <div className={styles.sidebarContainer}>
          {Object.values(tabConfigMap)
            .filter(config => !config.hidden)
            .map(config => {
              const isStepTab = config.key === tabConfigMap.steps.key;
              const isActive = config?.key === activeTab;
              return (
                <div key={config.key} className={styles.optionContainer}>
                  <div className={getClassnames(styles.option, !!isActive && styles.activeOption)} data-testid={`${config.key}-btn`}>
                    <ListItem
                      isClickable={!isStepTab}
                      item={getListItemConfig(config)}
                      onClickItem={!isStepTab && getOnToggleTab(config.key)}
                      showDescription={false}
                      optionMenuOverride={{ defaultBtnSize: ViewModel.CTASize.SMALL }}
                      actionListChildren={
                        [
                          !isStepTab && {
                            important: true,
                            type: ViewModel.CTAType.ACTION_SECONDARY,
                            size: ViewModel.CTASize.SMALL,
                            icon: isActive ? 'close' : 'chevron_right',
                            onClick: getOnToggleTab(config.key)
                          },
                          !!isStepTab && {
                            important: true,
                            type: ViewModel.CTAType.ACTION,
                            size: ViewModel.CTASize.SMALL,
                            helperText: 'Add step',
                            icon: 'add',
                            onClick: onAdd
                          }
                        ].filter(Boolean) as ComponentProps<typeof ListItem>['actionListChildren']
                      }
                    />
                  </div>
                  {!!isStepTab && (
                    <div>
                      <StepList activeTab={activeTab} activeStepKey={activeStepKey} flow={flow} onActiveStepChange={onActiveStepChange} onRemove={onRemove} />
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
