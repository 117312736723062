import { useContext, useMemo, useState } from 'react';
import { EMPTY } from 'rxjs';

import type { TagModel } from '@cyferd/client-engine';
import { ApiModel, createUUID, getParsedActionChildren, noop } from '@cyferd/client-engine';

import { GlobalContext } from '../../../../state-mgmt/GlobalState';
import { BondEditor } from '../../../shared/BondEditor';
import type { EditorContextValue } from '../../../shared/EditorHome';
import { EditorContext } from '../../../shared/EditorHome';
import { LinkingPageDiagram, PredefinedDiagrams } from '../../../../../@components/elements/LinkingPageDiagram';
import { EmptyLinkingPage } from '../../../../../@components/elements/EmptyLinkingPage';
import { UserTagSecurityModal } from '@components/elements/UserTagSecurityModal';

export const UserBondEditor = () => {
  const id = useMemo(createUUID, []);
  const [isSecurityOpen, setIsSecurityOpen] = useState<boolean>(false);
  const [selection, setSelection] = useState<ApiModel.ApiRecord>();
  const { item } = useContext<EditorContextValue<TagModel.Tag>>(EditorContext);
  const { deps } = useContext(GlobalContext);

  const cursor = useMemo(
    () => ({
      associationKey: 'hasAccess',
      filter: { $and: [{ enabled: { $eq: true } }] },
      relatedTo: { collectionId: ApiModel.ApiEntity.TAG, id: item?.id }
    }),
    [item]
  );

  const onOpenPermissions = (event: ApiModel.ApiRecord) => {
    setIsSecurityOpen(true);
    setSelection({
      fullItem: event,
      id: event?.id,
      bondId: event?.$r?.id,
      bondValues: Object.entries({ ...event?.$r?.values }).map(([key, value]) => ({ key, value: `${value}` }))
    });
    return EMPTY;
  };

  const onSuccess = () => deps.refresh$.next(id);

  return (
    <>
      {isSecurityOpen && (
        <UserTagSecurityModal
          open={isSecurityOpen}
          onClose={() => setIsSecurityOpen(false)}
          onSuccess={onSuccess}
          fullItem={selection.fullItem}
          collectionId={ApiModel.ApiEntity.USER}
          itemId={selection?.id}
          bondId={selection?.bondId}
          initialValue={selection?.bondValues}
          associationKey={'apps'}
        />
      )}
      <BondEditor
        componentId={id}
        item={item}
        fitToPage={true}
        cursor={cursor}
        collectionId={ApiModel.ApiEntity.USER}
        associationKey="hasAccess"
        modalTitle="Unlinked users"
        additionalActions={getParsedActionChildren([{ icon: 'security', label: 'Edit security', onClick: onOpenPermissions }])}
        customEmptyState={
          <EmptyLinkingPage
            title="Nothing linked"
            subtitle="Users have access to view everything that is connected to an app"
            ctaLabel="Link user"
            ctaOnClick={noop}
            diagram={<LinkingPageDiagram value={PredefinedDiagrams.appLinkedUsers} />}
          />
        }
      />
    </>
  );
};
