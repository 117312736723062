import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  mainContainer: css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${GAP.S};
  `,
  image: css`
    margin-bottom: ${GAP.XS};
  `,
  type: css`
    font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
    font-size: ${FONT_SIZE.XM};
    text-align: center;
    color: ${COLOR.BRAND_1};
    margin-bottom: ${GAP.XS};
  `,
  subtitle: css`
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    font-size: ${FONT_SIZE.XS};
    line-height: ${FONT_SIZE.S};
    text-align: center;
    color: ${COLOR.NEUTRAL_2};
    margin-bottom: ${GAP.S};
  `,
  title: css`
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    font-size: ${FONT_SIZE.S};
    line-height: ${FONT_SIZE.M};
    text-align: center;
    color: ${COLOR.NEUTRAL_1};
    margin-bottom: ${GAP.XXS};
  `,
  body: css`
    font-weight: ${FONT_WEIGHT.NORMAL};
    font-size: ${FONT_SIZE.S};
    line-height: ${FONT_SIZE.M};
    text-align: center;
    color: ${COLOR.NEUTRAL_1};
    margin-bottom: ${FONT_SIZE.M};
  `,
  link: css`
    cursor: pointer;
    font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
    font-size: ${FONT_SIZE.S};
    line-height: ${FONT_SIZE.M};
    color: ${COLOR.BRAND_1};
    text-align: center;
  `,
  imageUrl: {
    image: css`
      width: auto;
      max-height: 50px;
      margin: 0 auto;
    `
  }
};
