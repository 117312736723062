import { css } from '@emotion/react';
import { FONT_SIZE, FONT_WEIGHT, GAP, Z_INDEX } from '@constants';

export const styles = {
  bannerContainer: css`
    width: 100%;
    min-height: 18px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 16px;
    padding: ${GAP.S} ${GAP.M};
    z-index: ${Z_INDEX.BANNER};
    font-size: ${FONT_SIZE.XS};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  `,
  close: css`
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  `
};
