import { COLOR, CONTAINER_APP_PADDING, GAP, RADIUS, SECONDARY_COLOR, TRANSITION_SPEED } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  grid: css`
    display: grid;
    grid-gap: ${GAP.S};
    grid-template-rows: minmax(0, max-content);
    overflow: hidden;
    flex: 1;
    transition: grid-template-columns ${TRANSITION_SPEED};
    ${CONTAINER_APP_PADDING};
  `,
  size: (size: number) => {
    const gridTemplateColumnsMap = {
      3: '280px auto',
      2: '645px auto',
      1: 'calc(280px + 52%) auto'
    };

    return css`
      grid-template-columns: ${gridTemplateColumnsMap[size] || gridTemplateColumnsMap[1]};
    `;
  },
  sidebarContainer: css`
    background-color: ${COLOR.BASE_BACKGROUND_ALT_1};
    border-radius: ${RADIUS.M};
    padding: ${GAP.M} 0;
  `,
  gridCol: css`
    overflow: auto;
    flex: 1;
    scroll-behavior: smooth;
  `,
  preview: css`
    border: 3px ${SECONDARY_COLOR.BRAND_1} dashed;
    border-radius: ${RADIUS.S};
  `
};
