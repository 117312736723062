import { CONTAINER_WIDTH, Z_INDEX } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  mainWrapper: css`
    display: flex;
    flex-direction: column;
    width: 100lvw;
    height: 100lvh;
    overflow: hidden;
    position: fixed;
    z-index: ${Z_INDEX.LAYOUT};
  `,
  maxContainer: css`
    max-width: ${CONTAINER_WIDTH.MAX}px;
    align-self: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  `,
  header: css`
    display: flex;
  `,
  content: css`
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
  `,
  wrapper: css`
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
  `,
  nav: css`
    height: 100%;
    display: flex;
    flex-flow: column;
    position: relative;
    z-index: ${Z_INDEX.NAV_MENU};
  `
};
