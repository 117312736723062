import type { ComponentProps } from 'react';

import type { Adder, AdderOption } from '@components/elements/Adder';
/* istanbul ignore file | configs */
import { ApiModel } from '@cyferd/client-engine';
import { hasFlag } from '@utils';

const getGroupConfig = (groupId: string) => ApiModel.actionGroupMap[groupId] || { id: 'other', name: 'Other', order: 999 };

// Action list for Add modal
export const flowActionOptions: (user) => AdderOption[] = user =>
  Object.values(ApiModel.FlowSchemaMap)
    .filter(({ hidden, flag }: any) => hidden !== true && (!flag || hasFlag(user, flag)))
    .map(config => (config?.groups?.length ? config?.groups : [null]).map(groupId => ({ config, groupId })))
    .flat()
    .map(({ config, groupId }) => ({
      id: config.id,
      item: {
        title: config.name,
        description: config.description,
        image: 'play_arrow',
        color: 'NEUTRAL_4'
      },
      group: getGroupConfig(groupId).name,
      groupId
    }))
    .sort((a, b) => getGroupConfig(a.groupId).order - getGroupConfig(b.groupId).order) as ComponentProps<typeof Adder>['options'];
