import { useContext } from 'react';

import type { ViewModel } from '@cyferd/client-engine';

import { isSavedRecord } from '@constants';
import type { EditorContextValue } from '../../../shared/EditorHome';
import { EditorContext } from '../../../shared/EditorHome';
import { EmptyState } from '@components/elements/EmptyState';
import { styles } from './styles';
import { CyView } from '@components/smart/CyView';

export const Preview = () => {
  const { item } = useContext<EditorContextValue<ViewModel.View>>(EditorContext);

  if (!item) return <EmptyState />;
  const isNew = !isSavedRecord(item);

  return <div className={styles.container}>{!isNew && <CyView viewId="FLOW" input={{ id: item.id, debug: true }} fitToPage={true} headerHidden={true} />}</div>;
};
