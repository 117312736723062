import { useMemo, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useLocation } from 'react-router';

import { ApiModel } from '@cyferd/client-engine';

import { BUILDER_DESCRIPTION, BUILDER_ICON, BUILDER_ROUTE, QUERY_PARAM, SECTION_NAME, TRANS, isSavedRecord } from '@constants';
import { useQueryParamState } from '@utils/useQueryParamState';
import { EditorHome, useEditorHomeEffect, useEditorHomeOnRemove, useEditorHomeOnSave } from '../shared/EditorHome';
import { AppBondEditor } from './components/AppBondEditor';
import { GeneralInfo } from './components/GeneralInfo';
import { GeneralList } from './components/GeneralList';
import { IntegrationLogs } from './components/IntegrationLogs';
import { DOC_ID, useOptionListForDocs } from '@components/elements/Docs/resources';

export const IntegrationEditor = () => {
  const { pathname } = useLocation();
  const [queryParamState] = useQueryParamState();
  const itemId = queryParamState[QUERY_PARAM.RECORD_ID];
  const [original, setOriginal] = useState<any>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const isNew = !isSavedRecord(original);
  const docIds = useMemo(
    () => [DOC_ID.INTEGRATIONS_OVERVIEW, DOC_ID.INTEGRATIONS_TYPES, DOC_ID.INTEGRATIONS_MANAGING, DOC_ID.INTEGRATIONS_REMOTE, DOC_ID.INTEGRATIONS_ADVANCED],
    []
  );
  const optionListForDocs = useOptionListForDocs(docIds);

  const routeList = useMemo(
    () =>
      [
        { route: BUILDER_ROUTE.INTEGRATION_EDITOR.CHILDREN.LIST, label: null },
        { route: BUILDER_ROUTE.INTEGRATION_EDITOR.CHILDREN.GENERAL, label: TRANS.client.nav.builder.tabs.details },
        !isNew && { route: BUILDER_ROUTE.INTEGRATION_EDITOR.CHILDREN.LOGS, label: TRANS.client.nav.builder.tabs.logs },
        !isNew && { route: BUILDER_ROUTE.INTEGRATION_EDITOR.CHILDREN.TAG_BOND, label: TRANS.client.nav.builder.tabs.linkedApps }
      ].filter(Boolean),
    [isNew]
  );

  const onSave = useEditorHomeOnSave(ApiModel.ApiEntity.INTEGRATION);
  const onRemove = useEditorHomeOnRemove(ApiModel.ApiEntity.INTEGRATION);

  useEditorHomeEffect({ itemId, collectionId: ApiModel.ApiEntity.INTEGRATION, setOriginal, newItemGetter: undefined, setLoading });

  return (
    <EditorHome
      isLoading={isLoading}
      editorTitle={SECTION_NAME.INTEGRATIONS}
      title={original?.name || TRANS.client.nav.builder.newNames.integrations}
      color={original?.recordColor}
      icon={BUILDER_ICON.INTEGRATIONS}
      item={original}
      subtitle={BUILDER_DESCRIPTION.INTEGRATIONS}
      /** header */
      routeList={routeList}
      rootPath={BUILDER_ROUTE.INTEGRATION_EDITOR.ROOT}
      isCurrentDraft={isNew}
      hideSubHeader={[
        '/',
        BUILDER_ROUTE.INTEGRATION_EDITOR.ROOT,
        `${BUILDER_ROUTE.INTEGRATION_EDITOR.ROOT}${BUILDER_ROUTE.INTEGRATION_EDITOR.CHILDREN.LIST}`
      ].includes(pathname)}
      onSave={onSave}
      onRemove={onRemove}
      onSetOriginalValue={setOriginal}
      tabsIOptionMenu={optionListForDocs}
      activityLogConfig={{ collectionId: ApiModel.ApiEntity.INTEGRATION, recordId: !isNew && itemId }}
    >
      <Routes>
        <Route path={BUILDER_ROUTE.INTEGRATION_EDITOR.CHILDREN.LIST} element={<GeneralList />} />
        <Route path={BUILDER_ROUTE.INTEGRATION_EDITOR.CHILDREN.GENERAL} element={<GeneralInfo />} />
        <Route path={BUILDER_ROUTE.INTEGRATION_EDITOR.CHILDREN.LOGS} element={<IntegrationLogs />} />
        <Route path={BUILDER_ROUTE.INTEGRATION_EDITOR.CHILDREN.TAG_BOND} element={<AppBondEditor />} />
        <Route path="*" element={<GeneralList />} />
      </Routes>
    </EditorHome>
  );
};
