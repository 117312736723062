import { CONTAINER_APP_PADDING } from '@constants';
import { css } from '@emotion/css';

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    ${CONTAINER_APP_PADDING};
  `
};
