import { COLOR, CONTAINER_APP_PADDING } from '@constants';
import { css } from '@emotion/css';

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    ${CONTAINER_APP_PADDING};
  `,
  unlinkBtn: css`
    color: ${COLOR.NEUTRAL_2}!important;
  `,
  link: { color: COLOR.BRAND_1 }
};
