/* istanbul ignore file */
import { DROPDOWN_PORTAL_ID } from '@components/elements/DropDownOptionEditTable';
import { EVALUATOR_PORTAL_ID } from '@components/elements/Evaluator/resources';
import { POPOVER_PORTAL_ID } from '@components/elements/Popover';
import { ErrorPage } from '@components/platform/ErrorPage';
import { LoadingPageOverlay } from '@components/platform/LoadingPageOverlay';
import { MODAL_ID, SIDEBAR_ID } from '@constants';
import { ApiModel, ClientEngineContext } from '@cyferd/client-engine';
import { useCyActions } from '@utils';
import { memo, useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { BuilderApp } from './builder/BuilderApp';
import { ModalInteraction } from './builder/views/ModalInteraction';
import { AppWrapper } from './client-app/components/AppWrapper';
import { MessageList } from './client-app/components/MessageList';
import { ProgressBar } from './client-app/components/ProgressBar';
import { ToastList } from './client-app/components/ToastList';
import { LoginPage } from './client-app/pages/LoginPage';
import { MobilePage } from './client-app/pages/MobilePage';
import { ViewPage } from './client-app/pages/ViewPage';
import { NOTIFICATION_LATEST_POINTER } from './client-app/state-mgmt/notification';
import { useWSContext } from './client-app/state-mgmt/WSProvider';

export const App = memo(() => {
  const { isAuthenticated, isLoading } = useWSContext();

  useEffect(() => {
    const checkPageLoaded = () => {
      if (document.readyState === 'complete') {
        const loadingElement = document.getElementById('loading-container');
        const themeScript = document.getElementById('theme-script');

        if (loadingElement) {
          loadingElement.remove();
        }

        if (themeScript) {
          themeScript.remove();
        }

        window.removeEventListener('load', checkPageLoaded);
      }
    };

    if (document.readyState === 'complete') {
      checkPageLoaded();
    } else {
      window.addEventListener('load', checkPageLoaded);
    }

    return () => window.removeEventListener('load', checkPageLoaded);
  }, []);

  if (isAuthenticated) return <AuthenticatedApp isLoading={isLoading} />;
  return <UnauthenticatedApp />;
});

const AuthenticatedApp = memo(({ isLoading }: { isLoading: boolean }) => {
  const { useUserSelector } = useContext(ClientEngineContext);
  const { onCoreList } = useCyActions();
  const user = useUserSelector();

  useEffect(() => {
    onCoreList({
      pointer: NOTIFICATION_LATEST_POINTER,
      query: {
        cursor: {
          collectionId: ApiModel.ApiEntity.NOTIFICATION,
          options: { orderBy: 'createdAt', descending: true, limit: 1 },
          filter: { $and: [{ notified: { $matches: { id: user?.id } } }, { read: { $eq: false } }] }
        }
      }
    });
  }, [onCoreList, user.id]);

  return (
    <>
      {isLoading && <LoadingPageOverlay />}
      <ProgressBar />
      <AppWrapper>
        <Routes>
          <Route path="/builder/*" element={<BuilderApp />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/m/*" element={<MobilePage />} />
          <Route path="/*" element={<ViewPage />} />
        </Routes>
        <ToastList />
        <MessageList />
        <ModalInteraction />
        <div id={EVALUATOR_PORTAL_ID} />
        <div id={POPOVER_PORTAL_ID} />
        <div id={DROPDOWN_PORTAL_ID} />
        <div id={SIDEBAR_ID} />
        <div id={MODAL_ID} />
      </AppWrapper>
    </>
  );
});

const UnauthenticatedApp = memo(() => (
  <Routes>
    <Route path="/error" element={<ErrorPage />} />
    <Route path="/*" element={<LoginPage />} />
  </Routes>
));
