import type { FlowModel } from '@cyferd/client-engine';
import { COLOR, FONT_SIZE, FOREGROUND_COLOR } from '@constants';
import { IconImage } from '@components/elements/Icon/renderIcon';
import { ToolTip } from '@components/elements/Tooltip';
import { TooltipInfo } from '@components/elements/TooltipInfo';

import { styles } from './styles';

export const StartNode = ({ flow }: { flow: FlowModel.Flow }) => (
  <>
    <ToolTip text={<TooltipInfo title="Start" />}>
      <div className={styles.initialStep}>
        <div className={styles.getIconContainer('BRAND_1')}>
          <IconImage
            title=""
            icon="play_circle"
            imageProps={{
              css: { maxWidth: FONT_SIZE.XXXL, height: FONT_SIZE.XXXL, objectFit: 'cover', borderRadius: '100%', backgroundColor: COLOR.BASE_BACKGROUND }
            }}
            iconProps={{ size: FONT_SIZE.XL, fill: FOREGROUND_COLOR['BRAND_1'] }}
          />
        </div>
      </div>
    </ToolTip>
    <div style={{ display: 'flex' }}>
      {!!flow.schedules?.length && (
        <div className={styles.getIconContainer('BASE_BACKGROUND')} onClick={() => console.log('AAAAAH UN BICHO')}>
          <IconImage title="Scheduled" icon="schedule" />
        </div>
      )}
    </div>
  </>
);
