import { CONTAINER_APP_PADDING, GAP } from '@constants';
import { css } from '@emotion/css';

export const styles = {
  container: css`
    ${CONTAINER_APP_PADDING};
  `,
  infoLabelValueContainer: css`
    margin-top: ${GAP.S};
  `,
  infoLabelValueDiagram: css`
    margin-top: ${GAP.L};
  `
};
