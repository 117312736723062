import { memo, useMemo, useState } from 'react';

import type { FlowModel } from '@cyferd/client-engine';
import { ViewModel, getClassnames } from '@cyferd/client-engine';

import { defaultStepImage } from '../../../constants';
import { styles } from './styles';
import { COLOR } from '@constants';
import { ListItem } from '@components/elements/List';
import { CTAType } from '@components/elements/CTA';
import { SearchInput } from '@components/elements/SearchInput';

export interface StepListProps {
  flow: FlowModel.Flow;
  activeStepKey: string;
  onActiveStepChange: (stepKey: string) => void;
  stepActions: {
    icon: string;
    color: string;
    label: string;
    onClick: any;
    shouldHide?: any;
  }[];
}

const getResultIdList = (list: FlowModel.FlowRouting[]) => (list || []).map(r => r?.goTo).filter(Boolean);

const getNextIdListRecursive = (nextId: string, steps: FlowModel.Flow['steps'], total: string[]) => {
  if (total.includes(nextId)) return total;
  const nextList = Array.from(new Set([...getResultIdList(steps?.[nextId]?.onResult), ...getResultIdList(steps?.[nextId]?.onError)]));

  return nextList.reduce((t, curr) => getNextIdListRecursive(curr, steps, t).flat(), [...total, nextId]);
};

export const StepList = memo(({ flow, activeStepKey, onActiveStepChange, stepActions }: StepListProps) => {
  const [filter, setFilter] = useState<string>('');

  const stepEntries = useMemo(() => {
    const keys = Object.keys(flow?.steps || {});
    const orderedKeys = getResultIdList(flow.onStart)
      .map(id => getNextIdListRecursive(id, flow?.steps, []))
      .flat();
    const completeKeys = Array.from(new Set([...orderedKeys, ...keys]));
    const entries = completeKeys.filter(key => flow?.steps?.[key]).map(key => [key, flow?.steps?.[key]]) as [string, FlowModel.FlowStep][];
    return entries;
  }, [flow?.steps, flow.onStart]);

  return (
    <div className={styles.container} data-testid="step-list">
      <SearchInput testid="step-filter" value={filter} onChange={setFilter} />
      {stepEntries
        .filter(([key, step]) => !filter || [key, step?.name].some(s => s?.toLowerCase()?.includes(filter.toLowerCase())))
        .map(([key, step]) => {
          const isActiveStep = key === activeStepKey;
          const color = COLOR[step?.metadata?.color] ? step?.metadata?.color : 'BRAND_1';
          const onDetail = () => onActiveStepChange(key) as any;
          return (
            <div key={key} id={key}>
              <div data-testid="step-title" className={getClassnames(styles.step, !!isActiveStep && styles.activeStep)}>
                <ListItem
                  item={{
                    raw: {},
                    fullItem: {},
                    list: [],
                    title: step?.name,
                    description: step?.description,
                    image: step?.metadata?.image || defaultStepImage,
                    color
                  }}
                  showDescription={false}
                  isClickable={true}
                  onClickItem={onDetail}
                  optionMenuOverride={{ defaultBtnType: CTAType.ACTION_SECONDARY, defaultBtnSize: ViewModel.CTASize.SMALL }}
                  actionListChildren={
                    stepActions
                      .filter(({ shouldHide }) => !shouldHide?.(key, step))
                      .map(({ icon, color, label, onClick }) => ({
                        type: ViewModel.CTAType.LINK,
                        color,
                        icon,
                        label,
                        onClick: () => onClick(key, step)
                      })) as any
                  }
                />
              </div>
            </div>
          );
        })}
    </div>
  );
});
