import type { SearchProps } from '@components/elements/Search';
import { APP_CONTAINER_ID } from '@constants';
import { ClientEngineContext, useUnmountObservable } from '@cyferd/client-engine';
import type { Storage } from '@utils';
import { useGetElementSize } from '@utils';
import type { PropsWithChildren } from 'react';
import { useCallback, useContext, useState } from 'react';
import { BannerNewLayout } from '../BannerNewLayout';
import { CompactGlobalHeader } from '../GlobalHeader';
import { NavigationMenuNewLayout } from '../GlobalHeader/components/NavigationMenu';
import { useGlobalHeaderFetcher } from '../GlobalHeader/hooks/useGlobalHeaderFetcher';
import useBannerData from './hooks/useBannerData';
import { styles } from './styles';

export interface IMainLayoutConditional extends PropsWithChildren {
  hidden?: boolean;
  searchMenu?: SearchProps;
  hiddenFavorites?: boolean;
  hiddenSearch?: boolean;
  hiddenShortlink?: boolean;
  hiddenNotifications?: boolean;
  hiddenPreferences?: boolean;
  hiddenPrint?: boolean;
  storage?: Storage;
}

const MainLayoutConditional = ({ children, hidden, ...rest }: IMainLayoutConditional) => {
  if (hidden) return <MainLayoutWithoutHeader>{children}</MainLayoutWithoutHeader>;
  return <MainLayout {...rest}>{children}</MainLayout>;
};

const MainLayout = ({
  children,
  searchMenu,
  hiddenSearch,
  hiddenShortlink,
  hiddenFavorites,
  hiddenNotifications,
  hiddenPreferences,
  hiddenPrint
}: Omit<IMainLayoutConditional, 'hidden'>) => {
  const [isLeftPanelOpen, setIsLeftPanelOpen] = useState(false);
  const { useUserSelector } = useContext(ClientEngineContext);
  const { bannerData, isBannerTop, containBannerData } = useBannerData();
  const onDestroy$ = useUnmountObservable();
  const { isLoading, apps, recentViews } = useGlobalHeaderFetcher(onDestroy$);
  const user = useUserSelector();
  const { ref: bannerRef, height: bannerHeight } = useGetElementSize();

  const toggleLeftPanel = useCallback(() => setIsLeftPanelOpen(prevState => !prevState), []);

  const renderBanner = useCallback(() => {
    return <BannerNewLayout {...bannerData} ref={bannerRef} />;
  }, [bannerData, bannerRef]);

  return (
    <div data-testid="main-layout" css={styles.mainWrapper}>
      <div css={styles.maxContainer}>
        {containBannerData && <div style={{ order: isBannerTop ? -1 : 1 }}>{renderBanner()}</div>}
        <div css={styles.header}>
          {/** Slot for GlobalHeader */}
          <CompactGlobalHeader
            searchMenu={searchMenu}
            hiddenSearch={hiddenSearch}
            hiddenShortlink={hiddenShortlink}
            hiddenFavorites={hiddenFavorites}
            hiddenNotifications={hiddenNotifications}
            hiddenPreferences={hiddenPreferences}
            hiddenPrint={hiddenPrint}
            bannerHeight={isBannerTop ? 0 : bannerHeight}
          />
        </div>
        <div css={styles.wrapper}>
          <div css={styles.nav}>
            {/** Slot for navigation menu */}
            <NavigationMenuNewLayout
              isLoading={isLoading}
              data={apps}
              user={user}
              isOpen={isLeftPanelOpen}
              recentViews={recentViews}
              onOpenChange={toggleLeftPanel}
            />
          </div>
          <div css={styles.content} id={APP_CONTAINER_ID}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

const MainLayoutWithoutHeader = ({ children }: PropsWithChildren) => {
  const { bannerData, isBannerTop, containBannerData } = useBannerData();
  return (
    <div data-testid="main-layout-hidden-header" css={styles.mainWrapper}>
      <div css={styles.maxContainer}>
        {containBannerData && <div style={{ order: isBannerTop ? -1 : 1 }}>{<BannerNewLayout {...bannerData} />}</div>}
        <div css={styles.content} id={APP_CONTAINER_ID}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainLayoutConditional;
