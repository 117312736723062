/* istanbul ignore file */

import type { Edge, Elements, Node } from 'react-flow-renderer';
import { ArrowHeadType } from 'react-flow-renderer';

import type { FlowModel } from '@cyferd/client-engine';
import { getClassnames } from '@cyferd/client-engine';
import { COLOR, FONT_SIZE, FONT_WEIGHT, FOREGROUND_COLOR, RADIUS } from '@constants';

import { styles } from './styles';
import { ToolTip } from '@components/elements/Tooltip';
import { TooltipInfo } from '@components/elements/TooltipInfo';
import { IconImage } from '@components/elements/Icon/renderIcon';

const getEdgeBase = (step: FlowModel.FlowStep, routeIndex: number, routeItem: FlowModel.FlowRouting, style?: React.CSSProperties): Partial<Edge> => ({
  id: `${step.id}-${routeIndex}-${routeItem.goTo}`,
  source: step.id,
  target: routeItem.goTo,
  style: { stroke: COLOR.BRAND_1, strokeWidth: 2, strokeDasharray: routeItem.if ? 7 : 0, ...style },
  label: `${routeIndex + 1}`,
  labelStyle: { fontWeight: Number(FONT_WEIGHT.BOLD) },
  type: 'bezier',
  arrowHeadType: ArrowHeadType.ArrowClosed,
  data: { parentId: step.id }
});

export const getStepElements = (
  initialStepId: string,
  steps: FlowModel.Flow['steps'],
  step: FlowModel.FlowStep,
  usedStepIdList: string[],
  parentId?: string
): Elements => {
  if (!step || usedStepIdList.includes(step.id)) return [];
  const isInitialStep = step?.id === initialStepId;
  const stepNode = {
    id: step.id,
    targetPosition: 'left',
    sourcePosition: 'right',
    data: {
      stepId: step.id,
      parentId,
      name: step.name,
      label: (
        <ToolTip
          text={
            <TooltipInfo
              title={step.name}
              description={[!!step.action && `Action ${step.action?.replaceAll('.', ' ').toLowerCase()}`, step.description].filter(Boolean).join('. ')}
            />
          }
        >
          <div className={getClassnames(styles.getCardContainer(step.metadata?.color), !!isInitialStep && styles.initialStep)}>
            {!!step.metadata?.image && (
              <div className={styles.getIconContainer(step.metadata?.color)}>
                <IconImage
                  title=""
                  icon={step.metadata?.image as any}
                  imageProps={{
                    css: { maxWidth: FONT_SIZE.XXXL, height: FONT_SIZE.XXXL, objectFit: 'cover', borderRadius: '100%', backgroundColor: COLOR.BASE_BACKGROUND }
                  }}
                  iconProps={{ size: isInitialStep ? FONT_SIZE.XL : FONT_SIZE.M, fill: FOREGROUND_COLOR[step.metadata?.color || 'BRAND_1'] }}
                />
              </div>
            )}
            {!!step.name && <span className={styles.stepName}>{step.name}</span>}
          </div>
        </ToolTip>
      )
    },
    style: { margin: 0, padding: 0, border: 0, borderRadius: RADIUS.M, ...(isInitialStep ? { borderRadius: '100%', width: 50, height: 50 } : {}) },
    position: { x: 0, y: 0 }
  } as Node;

  const nextElements: Edge[] = (step.onResult || [])
    .filter(routeItem => routeItem?.goTo)
    .reduce((total, routeItem, routeIndex, list) => {
      const color = routeItem.color || 'BRAND_1';
      const label = [routeItem.title || (list.length > 1 && routeIndex + 1)].filter(Boolean).join(' ').trim() || null;
      return [
        ...total,
        {
          ...getEdgeBase(step, routeIndex, routeItem, { stroke: COLOR[color] }),
          id: `${step.id}-${routeIndex}-${routeItem.goTo}`,
          labelStyle: { fill: FOREGROUND_COLOR[color], fontWeight: 600 },
          labelBgPadding: [8, 4],
          labelBgStyle: !!label && { fill: COLOR[color] },
          labelBgBorderRadius: 4,
          label
        } as Edge,
        ...getStepElements(initialStepId, steps, steps[routeItem.goTo], [...usedStepIdList, ...total.map(({ id }) => id), step.id], step.id)
      ];
    }, [])
    .flat();

  const errorElements: Edge[] = (step?.onError || [])
    .filter(routeItem => routeItem?.goTo)
    .reduce((total, routeItem, routeIndex, list) => {
      const color = routeItem.color || 'RD_4';
      const label = [routeItem.title || (list.length > 1 && routeIndex + 1)].filter(Boolean).join(' ').trim() || null;
      return [
        ...total,
        {
          ...getEdgeBase(step, routeIndex, routeItem, { stroke: COLOR[routeItem.color] || COLOR.RD_4 }),
          id: `${step.id}-error-${routeIndex}-${routeItem.goTo}`,
          labelStyle: { fill: FOREGROUND_COLOR[color], fontWeight: 600 },
          labelBgPadding: [10, 5],
          labelBgStyle: !!label && { fill: COLOR[color] },
          label
        } as Edge,
        ...getStepElements(
          initialStepId,
          steps,
          steps[routeItem.goTo],
          [...usedStepIdList, ...nextElements.map(({ id }) => id), ...total.map(({ id }) => id), step.id],
          step.id
        )
      ];
    }, [])
    .flat();

  return [stepNode, ...nextElements, ...errorElements];
};
