// istanbul ignore file
import { useCallback } from 'react';
import { ApiModel, ViewModel } from '@cyferd/client-engine';
import { useCyActions } from '@utils';
import type { IUseOnClickParams, IUseOnClickProps } from '../../types';
import type { IOnActionClick } from '../../hooks';
import { useOnActionClick } from '../../hooks';

export const useOnClick = ({
  rowActions: { disableOnClick, onClickType = ViewModel.CyTableOnClickType.RECORD },
  rowActions,
  isLoading,
  refreshId
}: IUseOnClickParams): IUseOnClickProps => {
  const { onDispatchNavigateTo, onDispatchFormModal } = useCyActions();
  const { onActionClick } = useOnActionClick();

  const onClick = useCallback(
    (item: Record<string, any> = {}, meta: Record<string, any> = {}) => {
      if (onClickType === ViewModel.CyTableOnClickType.FLOW) {
        onActionClick(rowActions as IOnActionClick, { record: item, event: { item, meta } });
        return;
      }

      const { collectionId, id } = item;

      if (!collectionId || !id) return;

      if (onClickType === ViewModel.CyTableOnClickType.NAVIGATE || meta.metaKey) {
        onDispatchNavigateTo({ path: 'DETAIL', qs: { collectionId, id }, openInNewTab: meta.metaKey }, meta);
        return;
      }

      onDispatchFormModal({
        type: ApiModel.ApiEntity.ENTITY,
        collectionId,
        recordId: id,
        formType: ViewModel.CyFormType.TABS,
        reusableActionOnSuccess: refreshId
      });
    },
    [rowActions, refreshId, onClickType, onActionClick, onDispatchFormModal, onDispatchNavigateTo]
  );

  if (isLoading || disableOnClick) return {};
  return { onClick };
};
