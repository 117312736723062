/* istanbul ignore file */

import { Position, type Edge, type Node } from 'reactflow';

import type { FlowModel } from '@cyferd/client-engine';
import { COLOR, FONT_WEIGHT, FOREGROUND_COLOR, RADIUS } from '@constants';
import { ChartNode } from './components/ChartNode';
import { StartNode } from './components/StartNode';

// x for the first node from initial step
const START_X = 120;
// we leave room for unconnected steps
const START_Y = 100;
const SEPARATION_X = 220;
const SEPARATION_Y = 100;
const NODE_HEIGHT = 75;
const NODE_WIDTH = 100;

const makeEdge =
  (step: FlowModel.FlowStep, activeStepId: string, isError = false) =>
  (routeItem: FlowModel.FlowRouting, routeIndex: number, total): Edge => {
    const isActive = [undefined, step.id, routeItem.goTo].includes(activeStepId);
    const stroke = isActive ? COLOR[routeItem.color] || (isError ? COLOR.RD_4 : COLOR.BRAND_1) : COLOR.NEUTRAL_3;

    const zIndex = isActive ? 100 : 50;
    return {
      id: [step.id, isError ? 'error' : false, routeIndex, routeItem.goTo].filter(e => e !== false).join('-'),
      source: step.id,
      target: routeItem.goTo,
      style: { stroke, strokeWidth: 2, strokeDasharray: routeItem.if ? 7 : 0, zIndex },
      type: step.id === routeItem.goTo ? 'selfconnecting' : 'smoothstep',
      data: { parentId: step.id, parentAction: step.action, isError, routeIndex },
      pathOptions: {},
      ...(total.length > 1 || routeItem.title
        ? {
            label: routeItem.title || `${routeIndex + 1}`,
            labelBgStyle: { fill: COLOR[routeItem.color], opacity: isActive ? 1 : 0.3 },
            labelStyle: { fill: FOREGROUND_COLOR[routeItem.color], fontWeight: Number(FONT_WEIGHT.BOLD), opacity: isActive ? 1 : 0.3, zIndex: zIndex },
            labelBgPadding: [10, 5],
            labelBgBorderRadius: 4
          }
        : {})
    };
  };

const makeNode = (step: FlowModel.FlowStep, activeStepId: string, position): Node => ({
  id: step.id,
  sourcePosition: Position.Right,
  targetPosition: Position.Left,
  data: {
    id: step.id,
    action: step.action,
    name: step.name,
    debug: step.debug,
    notes: step.notes,
    label: <ChartNode step={step} activeStepId={activeStepId} />
  },
  style: { margin: 0, padding: 0, border: 0, borderRadius: RADIUS.M, width: NODE_WIDTH, height: NODE_HEIGHT },
  position,
  width: NODE_WIDTH,
  height: NODE_HEIGHT
});

const makeStartNode: (flow: FlowModel.Flow) => Node = flow => ({
  id: 'initial-step',
  type: 'input',
  sourcePosition: Position.Right,
  data: {
    id: 'initial-step',
    name: 'Initial step',
    label: <StartNode flow={flow} />
  },
  style: { margin: 0, padding: 0, border: 0, borderRadius: '100%', width: 50, height: 50 },
  position: { x: 0, y: START_Y + 12.5 }, // compensate for size difference
  width: 50,
  height: 50
});

export const getElements = (flow: FlowModel.Flow, activeStepId: string) => {
  const nodeSet = new Set();
  const nodes = [makeStartNode(flow)];
  const edges = [];

  const processRoute =
    (step, position, isError = false) =>
    (route, index, total) => {
      if (!flow?.steps?.[route.goTo]) return;
      // eslint-disable-next-line no-restricted-syntax
      edges.push(makeEdge(step, activeStepId, isError)(route, index, total));
      followTheRoute(route.goTo, flow.steps[route.goTo], { x: position.x, y: position.y });
    };

  const followTheRoute = (key, step, position) => {
    if (nodeSet.has(key)) return;
    nodeSet.add(key);
    // eslint-disable-next-line no-restricted-syntax
    nodes.push(makeNode(step, activeStepId, position));

    const x = position.x + SEPARATION_X;
    // we are going to increase the value of y for each new node we connect
    let y = position.y - SEPARATION_Y;

    step.onResult?.forEach((route, index, total) => processRoute(step, { x, y: step.id === route.goTo ? y : (y += SEPARATION_Y) })(route, index, total));
    step.onError?.forEach((route, index, total) => processRoute(step, { x, y: step.id === route.goTo ? y : (y += SEPARATION_Y) }, true)(route, index, total));
  };

  flow?.onStart?.forEach((route, index, total) => processRoute({ id: 'initial-step' }, { x: START_X, y: START_Y + SEPARATION_Y * index })(route, index, total));

  Object.entries(flow?.steps || {})
    .filter(([key]) => !nodeSet.has(key))
    .forEach(([, step]: [string, FlowModel.FlowStep], i) => {
      // eslint-disable-next-line no-restricted-syntax
      nodes.push(makeNode(step, activeStepId, { x: i * 120, y: 0 }));
      // eslint-disable-next-line no-restricted-syntax
      edges.push(...(step.onResult?.map(makeEdge(step, activeStepId)) || []), ...(step.onError?.map(makeEdge(step, activeStepId, true)) || []));
    });

  return { nodes, edges };
};
