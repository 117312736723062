import type { FlowModel } from '@cyferd/client-engine';
import { getClassnames } from '@cyferd/client-engine';
import { COLOR, FONT_SIZE, FOREGROUND_COLOR } from '@constants';
import { IconImage } from '@components/elements/Icon/renderIcon';
import { PopoverTrigger } from '@components/elements/Popover';
import { ToolTip } from '@components/elements/Tooltip';
import { TooltipInfo } from '@components/elements/TooltipInfo';

import { styles } from './styles';

export const ChartNode = ({ step, activeStepId }: { step: FlowModel.FlowStep; activeStepId: string }) => (
  <>
    <ToolTip text={<TooltipInfo title={step.name} description={[step.action, step.description].filter(Boolean).join('. ')} />}>
      <div className={getClassnames(styles.getCardContainer(step.metadata?.color, activeStepId && activeStepId !== step.id))}>
        {!!step.metadata?.image && (
          <div className={styles.getIconContainer(step.metadata?.color)}>
            <IconImage
              title=""
              icon={step.metadata?.image as any}
              imageProps={{
                css: { maxWidth: FONT_SIZE.XXXL, height: FONT_SIZE.XXXL, objectFit: 'cover', borderRadius: '100%', backgroundColor: COLOR.BASE_BACKGROUND }
              }}
              iconProps={{ size: FONT_SIZE.M, fill: FOREGROUND_COLOR[step.metadata?.color || 'BRAND_1'] }}
            />
          </div>
        )}
        {!!step.name && <span className={styles.stepName}>{step.name}</span>}
      </div>
    </ToolTip>
    <div style={{ display: 'flex' }}>
      {step.debug && (
        <div className={styles.getIconContainer('OE_2')}>
          <IconImage title="Debug" icon="pest_control" />
        </div>
      )}
      {step.notes && (
        <PopoverTrigger value={step.notes}>
          <div className={styles.getIconContainer('YW_1')}>
            <IconImage title="Notes" icon="note" />
          </div>
        </PopoverTrigger>
      )}
    </div>
  </>
);
