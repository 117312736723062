import { BaseEdge, type EdgeProps } from 'reactflow';

export const SelfConnectingEdge = (props: EdgeProps) => {
  const { sourceX, sourceY, targetX, targetY } = props;
  const radiusX = (sourceX - targetX) * 0.66;
  const radiusY = 30;
  const edgePath = `M ${sourceX - 5} ${sourceY} A ${radiusX} ${radiusY} 0 1 1 ${targetX + 2} ${targetY}`;

  return <BaseEdge {...props} path={edgePath} labelX={sourceX - 50} labelY={sourceY + 50} />;
};
