import { useMemo, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router';

import type { TagModel } from '@cyferd/client-engine';
import { ApiModel } from '@cyferd/client-engine';

import { BUILDER_DESCRIPTION, ENV, SECTION_NAME, TRANS, isSavedRecord } from '@constants';
import { BUILDER_ROUTE, QUERY_PARAM } from '@constants';
import { getNewTag } from '@models/tag';
import { useOnOpenExternalUrl } from '@utils/useOnOpenExternalUrl';
import { useQueryParamState } from '@utils/useQueryParamState';
import { EditorHome, useEditorHomeEffect, useEditorHomeOnRemove, useEditorHomeOnSave } from '../shared/EditorHome';
import { EntityBondEditor } from './components/EntityBondEditor';
import { FlowBondEditor } from './components/FlowBondEditor';
import { GeneralInfo } from './components/GeneralInfo';
import { GeneralList } from './components/GeneralList';
import { IntegrationBondEditor } from './components/IntegrationBondEditor';
import { UserBondEditor } from './components/UserBondEditor';
import { ViewBondEditor } from './components/ViewBondEditor';
import { ViewSettings } from './components/ViewSettings';
import { DOC_ID, useOptionListForDocs } from '@components/elements/Docs/resources';

export const TagEditor = () => {
  const { pathname } = useLocation();
  const [queryParamState] = useQueryParamState();
  const itemId = queryParamState[QUERY_PARAM.RECORD_ID];
  const [original, setOriginal] = useState<TagModel.Tag>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const isNew = !isSavedRecord(original);
  const docIds = useMemo(() => [DOC_ID.APPS_OVERVIEW, DOC_ID.APPS_ASSETS, DOC_ID.APPS_MANAGE, DOC_ID.APPS_ADVANCED], []);
  const optionListForDocs = useOptionListForDocs(docIds);

  const routeList = useMemo(
    () =>
      [
        { route: BUILDER_ROUTE.TAG_EDITOR.CHILDREN.LIST, label: null },
        { route: BUILDER_ROUTE.TAG_EDITOR.CHILDREN.GENERAL, label: TRANS.client.nav.builder.tabs.details },
        !isNew && { route: BUILDER_ROUTE.TAG_EDITOR.CHILDREN.VIEW_SETTINGS, label: TRANS.client.nav.builder.tabs.coreViewConfig },
        !isNew && { route: BUILDER_ROUTE.TAG_EDITOR.CHILDREN.DATA_BOND, label: TRANS.client.nav.builder.tabs.linkedCollections },
        !isNew && { route: BUILDER_ROUTE.TAG_EDITOR.CHILDREN.FLOW_BOND, label: TRANS.client.nav.builder.tabs.linkedFlows },
        !isNew && { route: BUILDER_ROUTE.TAG_EDITOR.CHILDREN.USER_BOND, label: TRANS.client.nav.builder.tabs.linkedUsers },
        !isNew && { route: BUILDER_ROUTE.TAG_EDITOR.CHILDREN.VIEW_BOND, label: TRANS.client.nav.builder.tabs.linkedViews },
        !isNew && { route: BUILDER_ROUTE.TAG_EDITOR.CHILDREN.INTEGRATION_BOND, label: TRANS.client.nav.builder.tabs.linkedIntegrations }
      ].filter(Boolean),
    [isNew]
  );

  const onSave = useEditorHomeOnSave(ApiModel.ApiEntity.TAG);
  const onRemove = useEditorHomeOnRemove(ApiModel.ApiEntity.TAG);

  useEditorHomeEffect({ itemId, collectionId: ApiModel.ApiEntity.TAG, setOriginal, newItemGetter: getNewTag, setLoading });

  const openExternalUrl = useOnOpenExternalUrl(`${ENV.CLIENT_APP_URL}/APP_HOME?id=${original?.id}`);

  return (
    <EditorHome
      isLoading={isLoading}
      editorTitle={SECTION_NAME.APPS}
      title={original?.name || TRANS.client.nav.builder.newNames.apps}
      item={original}
      subtitle={BUILDER_DESCRIPTION.APPS}
      icon={original?.recordImage}
      color={original?.recordColor}
      /** header */
      routeList={routeList}
      rootPath={BUILDER_ROUTE.TAG_EDITOR.ROOT}
      isCurrentDraft={isNew}
      hideSubHeader={['/', BUILDER_ROUTE.TAG_EDITOR.ROOT, `${BUILDER_ROUTE.TAG_EDITOR.ROOT}${BUILDER_ROUTE.TAG_EDITOR.CHILDREN.LIST}`].includes(pathname)}
      onSave={onSave}
      onRemove={onRemove}
      onSetOriginalValue={setOriginal}
      additionalButtonList={!isNew && [{ icon: 'zoom_in', label: TRANS.client.buttons.open, onClick: openExternalUrl as any }]}
      tabsIOptionMenu={optionListForDocs}
      activityLogConfig={{ collectionId: ApiModel.ApiEntity.TAG, recordId: !isNew && itemId }}
    >
      <Routes>
        <Route path={BUILDER_ROUTE.TAG_EDITOR.CHILDREN.LIST} element={<GeneralList />} />
        <Route path={BUILDER_ROUTE.TAG_EDITOR.CHILDREN.GENERAL} element={<GeneralInfo />} />
        <Route path={BUILDER_ROUTE.TAG_EDITOR.CHILDREN.DATA_BOND} element={<EntityBondEditor />} />
        <Route path={BUILDER_ROUTE.TAG_EDITOR.CHILDREN.FLOW_BOND} element={<FlowBondEditor />} />
        <Route path={BUILDER_ROUTE.TAG_EDITOR.CHILDREN.USER_BOND} element={<UserBondEditor />} />
        <Route path={BUILDER_ROUTE.TAG_EDITOR.CHILDREN.VIEW_BOND} element={<ViewBondEditor />} />
        <Route path={BUILDER_ROUTE.TAG_EDITOR.CHILDREN.VIEW_SETTINGS} element={<ViewSettings />} />
        <Route path={BUILDER_ROUTE.TAG_EDITOR.CHILDREN.INTEGRATION_BOND} element={<IntegrationBondEditor />} />
        <Route path="*" element={<GeneralList />} />
      </Routes>
    </EditorHome>
  );
};
