import { CONTAINER_APP_PADDING, CONTAINER_WIDTH } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    max-width: ${CONTAINER_WIDTH.MAX}px;
    margin: auto;
    ${CONTAINER_APP_PADDING}
  `
};
