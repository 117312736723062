import { useMemo, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router';

import type { FlowModel } from '@cyferd/client-engine';
import { ApiModel } from '@cyferd/client-engine';

import { BUILDER_DESCRIPTION, BUILDER_ICON, BUILDER_ROUTE, ENV, QUERY_PARAM, SECTION_NAME, TRANS, isSavedRecord } from '@constants';
import { getNewFlow } from '@models/flow';
import { useOnOpenExternalUrl } from '@utils/useOnOpenExternalUrl';
import { useQueryParamState } from '@utils/useQueryParamState';
import { EditorHome, useEditorHomeEffect, useEditorHomeOnRemove, useEditorHomeOnSave } from '../shared/EditorHome';
import { AppBondEditor } from './components/AppBondEditor';
import { EntityBondEditor } from './components/EntityBondEditor';
import { FlowDefinition } from './components/FlowDefinition';
import { FlowLogs } from './components/FlowLogs';
import { GeneralInfo } from './components/GeneralInfo';
import { GeneralList } from './components/GeneralList';
import { Preview } from './components/Preview';
import { DOC_ID, useOptionListForDocs } from '@components/elements/Docs/resources';

export const FlowEditor = () => {
  const { pathname } = useLocation();
  const [queryParamState] = useQueryParamState();
  const itemId = queryParamState[QUERY_PARAM.RECORD_ID];
  const [original, setOriginal] = useState<FlowModel.Flow>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const isNew = !isSavedRecord(original);
  const docIds = useMemo(() => [DOC_ID.FLOWS_OVERVIEW, DOC_ID.FLOWS_COMPONENTS, DOC_ID.FLOWS_MANAGING, DOC_ID.FLOWS_STEPS], []);
  const optionListForDocs = useOptionListForDocs(docIds);

  const routeList = useMemo(
    () =>
      [
        { route: BUILDER_ROUTE.FLOW_EDITOR.CHILDREN.LIST, label: null },
        { route: BUILDER_ROUTE.FLOW_EDITOR.CHILDREN.GENERAL, label: TRANS.client.nav.builder.tabs.details },
        { route: BUILDER_ROUTE.FLOW_EDITOR.CHILDREN.STEPS, label: TRANS.client.nav.builder.tabs.definition },
        !isNew && { route: BUILDER_ROUTE.FLOW_EDITOR.CHILDREN.LOGS, label: TRANS.client.nav.builder.tabs.logs },
        !isNew && { route: BUILDER_ROUTE.FLOW_EDITOR.CHILDREN.PREVIEW, label: TRANS.client.nav.builder.tabs.preview },
        !isNew && { route: BUILDER_ROUTE.FLOW_EDITOR.CHILDREN.TAG_BOND, label: TRANS.client.nav.builder.tabs.linkedApps },
        !isNew && { route: BUILDER_ROUTE.FLOW_EDITOR.CHILDREN.ENTITY_BOND, label: TRANS.client.nav.builder.tabs.linkedCollections }
      ].filter(Boolean),
    [isNew]
  );

  const onSave = useEditorHomeOnSave(ApiModel.ApiEntity.FLOW);
  const onRemove = useEditorHomeOnRemove(ApiModel.ApiEntity.FLOW);

  useEditorHomeEffect({ itemId, collectionId: ApiModel.ApiEntity.FLOW, setOriginal, newItemGetter: getNewFlow, setLoading });

  const openExternalUrl = useOnOpenExternalUrl(`${ENV.CLIENT_APP_URL}/FLOW?id=${original?.id}`);

  return (
    <EditorHome
      isLoading={isLoading}
      editorTitle={SECTION_NAME.FLOWS}
      title={original?.name || TRANS.client.nav.builder.newNames.flows}
      color={original?.recordColor}
      icon={BUILDER_ICON.FLOWS}
      item={original}
      subtitle={BUILDER_DESCRIPTION.FLOWS}
      /** header */
      routeList={routeList}
      rootPath={BUILDER_ROUTE.FLOW_EDITOR.ROOT}
      isCurrentDraft={isNew}
      hideSubHeader={['/', BUILDER_ROUTE.FLOW_EDITOR.ROOT, `${BUILDER_ROUTE.FLOW_EDITOR.ROOT}${BUILDER_ROUTE.FLOW_EDITOR.CHILDREN.LIST}`].includes(pathname)}
      onSave={onSave}
      onRemove={onRemove}
      onSetOriginalValue={setOriginal}
      additionalButtonList={!isNew && [{ icon: 'zoom_in', label: TRANS.client.buttons.open, onClick: openExternalUrl as any }]}
      tabsIOptionMenu={optionListForDocs}
      activityLogConfig={{ collectionId: ApiModel.ApiEntity.FLOW, recordId: !isNew && itemId }}
    >
      <Routes>
        <Route path={BUILDER_ROUTE.FLOW_EDITOR.CHILDREN.LIST} element={<GeneralList />} />
        <Route path={BUILDER_ROUTE.FLOW_EDITOR.CHILDREN.GENERAL} element={<GeneralInfo />} />
        <Route path={BUILDER_ROUTE.FLOW_EDITOR.CHILDREN.STEPS} element={<FlowDefinition />} />
        <Route path={BUILDER_ROUTE.FLOW_EDITOR.CHILDREN.LOGS} element={<FlowLogs />} />
        <Route path={BUILDER_ROUTE.FLOW_EDITOR.CHILDREN.PREVIEW} element={<Preview />} />
        <Route path={BUILDER_ROUTE.FLOW_EDITOR.CHILDREN.TAG_BOND} element={<AppBondEditor />} />
        <Route path={BUILDER_ROUTE.FLOW_EDITOR.CHILDREN.ENTITY_BOND} element={<EntityBondEditor />} />
        <Route path="*" element={<GeneralList />} />
      </Routes>
    </EditorHome>
  );
};
