import type { ReactNode } from 'react';
import { useContext, useMemo } from 'react';
import type { ApiModel } from '@cyferd/client-engine';
import { ViewModel } from '@cyferd/client-engine';
import { CTA, CTAType } from '@components/elements/CTA';
import { CyWrapperContext } from '@components/smart/CyWrapper';
import { FiltersModal } from '@components/elements/FiltersModal';
import { Header } from '@components/elements/Header';
import { OptionMenu } from '@components/elements/OptionMenu';
import { QuickFilters } from '@components/elements/QuickFilters';
import { SearchInput } from '@components/elements/SearchInput';
import { CONTAINER_WIDTH, TRANS } from '@constants';
import type { useDataSource } from '../../hooks';
import { useSearchString } from './hooks/useSearchString';
import { useHeaderActions } from './hooks/useHeaderActions';
import { useHeaderQuickFilters } from './hooks/useHeaderQuickFilters';
import { styles } from './styles';

const testid = 'CyTableHeader';
const getTestId = (id: string) => `${testid}-${id}`;

export type CyTableHeaderProps = {
  refreshId: string;
  testid?: string;
  value?: ApiModel.ApiValue;
  width?: number;
  beforeTitle?: ReactNode;
  query?: ViewModel.CyTableProps['value']['query'];
  header?: ViewModel.CyTableProps['header'];
  data?: {
    updateCursor?: ReturnType<typeof useDataSource>['updateCursor'];
    fetch?: ReturnType<typeof useDataSource>['fetch'];
    canLoad?: ReturnType<typeof useDataSource>['canLoad'];
    isLoading?: ReturnType<typeof useDataSource>['isLoading'];
  };
  editMode?: {
    enabled?: boolean;
    editing?: boolean;
    toggleEditing?: () => void;
    onSave?: () => void;
    changeCount?: number;
  };
};

export const CyTableHeader = ({
  refreshId,
  value,
  header,
  header: { title, subtitle, hideSearch, hideAdvancedFilters, hideRefresh } = {},
  data: { updateCursor, fetch, canLoad, isLoading } = {},
  editMode: { enabled: canEditMode, editing, toggleEditing, onSave, changeCount } = {},
  width,
  query
}: CyTableHeaderProps) => {
  const { useFetchCollectionModel, renderSchemaForm } = useContext(CyWrapperContext);
  const { searchString, setSearchString, onSearchString } = useSearchString({ updateCursor });
  const fetchCollectionModel = useFetchCollectionModel();

  const actions = useHeaderActions({ value, header, isLoading, refreshId });
  const { quickFilters, hasQuickFilters } = useHeaderQuickFilters({ value, header });

  const shouldRenderHeader = useMemo(
    () => !!(title || actions?.length || hasQuickFilters || (canLoad && (!hideSearch || !hideAdvancedFilters)) || canEditMode),
    [title, actions?.length, hasQuickFilters, canEditMode, canLoad, hideSearch, hideAdvancedFilters]
  );
  const actionList = useMemo(() => (!editing ? actions : actions?.map(action => ({ ...action, disabled: true }))), [actions, editing]);

  if (!shouldRenderHeader) return null;

  const controls = (
    <div css={styles.controls} data-selector="controls">
      <div css={styles.headerActionList}>
        <OptionMenu maxImportant={4} defaultBtnType={ViewModel.CTAType.LINK} optionList={actionList} />
      </div>
      <div css={styles.headerSearchContainer}>
        <>
          {!hideSearch && canLoad && (
            <div css={styles.searchInputContainer}>
              <SearchInput
                testid={getTestId(`search-input`)}
                value={searchString}
                searchDelay={500}
                onChange={setSearchString}
                onClick={onSearchString}
                compact={width <= CONTAINER_WIDTH.XS}
                disabled={editing}
              />
            </div>
          )}

          {!hideAdvancedFilters && canLoad && (
            <div>
              <FiltersModal
                entity={query}
                displaySorting={true}
                fetchCollectionModel={fetchCollectionModel}
                cursor={query?.cursor}
                onSubmit={updateCursor}
                testid={getTestId(`advanced-filters`)}
                renderSchemaForm={renderSchemaForm}
                disabled={isLoading || editing}
              />
            </div>
          )}
        </>
        {!hideRefresh && canLoad && (
          <div>
            <CTA
              onClick={fetch}
              testid={getTestId(`refresh-btn`)}
              icon="refresh"
              disabled={isLoading || editing}
              hideLoading={true}
              type={CTAType.LINK}
              size={ViewModel.CTASize.LARGE}
              tooltip={TRANS.client.buttons.refresh}
            />
          </div>
        )}
      </div>
    </div>
  );

  const label = changeCount > 0 ? (changeCount === 1 ? `Save 1 change` : `Save ${changeCount} changes`) : 'Save';

  return (
    <div css={styles.headerContainer} data-testid={testid}>
      <Header
        testid={getTestId(`header`)}
        title={
          canEditMode ? (
            <div css={styles.titleContainer}>
              <div data-testid={getTestId(`edit-mode-btns`)} css={styles.editModeBtns}>
                {!editing ? (
                  <CTA size={ViewModel.CTASize.SMALL} onClick={toggleEditing} type={CTAType.ACTION} icon="edit" />
                ) : (
                  <>
                    <CTA
                      size={ViewModel.CTASize.SMALL}
                      color="GN_5"
                      label={label}
                      disabled={!changeCount}
                      onClick={onSave}
                      testid="CyTableHeader-edit-save-button"
                    />
                    <div css={styles.editControls}>
                      {editing && <CTA type={CTAType.LINK} size={ViewModel.CTASize.LARGE} label="Cancel" onClick={toggleEditing} />}
                    </div>
                  </>
                )}
              </div>
              <h1>{title}</h1>
            </div>
          ) : (
            title
          )
        }
        subtitle={subtitle}
        titleControls={
          hasQuickFilters ? (
            <div data-testid={getTestId(`quick-filters`)}>
              <QuickFilters
                config={quickFilters}
                cursor={value?.query?.cursor}
                disabled={isLoading || editing}
                onFetch={updateCursor}
                schema={value?.query?.schema}
              />
            </div>
          ) : null
        }
        controls={controls}
      />
    </div>
  );
};
