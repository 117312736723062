import { COLOR, CONTAINER_APP_PADDING, GAP } from '@constants';
import { css } from '@emotion/css';

export const styles = {
  container: css`
    display: grid;
    grid-template-columns: 300px auto;
    grid-gap: ${GAP.S};
    width: 100%;
    height: 100%;
    position: relative;
    ${CONTAINER_APP_PADDING};
  `,
  content: css`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 100%;
  `,
  sidebar: css`
    height: 100%;
    overflow: auto;
    scroll-behavior: smooth;
  `,
  mainContent: css`
    height: 100%;
    overflow: auto;
    flex: 1;
    display: grid;
    grid-template-rows: 2fr 1fr;
  `,
  mainContentFullChart: css`
    grid-template-rows: 1fr;
  `,
  chartContainer: css`
    min-height: 0;
    width: 100%;
    height: 100%;
  `,
  forms: css`
    min-height: 0;
    overflow: auto;
    padding-top: ${GAP.XS};
    border-top: 1px ${COLOR.NEUTRAL_2} solid;
    margin-top: auto;
  `,
  stepForms: css`
    display: grid;
    grid-template-rows: 50px auto;
  `,
  formContent: css`
    height: 100%;
    overflow: auto;
  `
};
