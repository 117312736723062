import { TRANSITION_SPEED, Z_INDEX } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    position: fixed;
    z-index: ${Z_INDEX.PROGRESS_BAR};
    top: 0;
    left: 0;
    width: 100%;
  `,
  bar: css`
    width: 100%;
    opacity: 0;
    transition: all ${TRANSITION_SPEED};
  `,
  visible: css`
    opacity: 1 !important;
  `
};
