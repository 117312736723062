import { useCallback, useContext, useMemo, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router';

import type { ViewModel } from '@cyferd/client-engine';
import { ApiModel, mergeTruthy, normalize } from '@cyferd/client-engine';

import { isEmpty } from 'lodash';

import { BUILDER_DESCRIPTION, ENV, SECTION_NAME, TRANS, isSavedRecord } from '@constants';
import { BUILDER_ROUTE, QUERY_PARAM } from '@constants';
import { getNewView } from '@models/view';
import { useQueryParamState } from '@utils/useQueryParamState';
import { EditorHome, useEditorHomeEffect, useEditorHomeOnRemove, useEditorHomeOnSave } from '../shared/EditorHome';
import { AppBondEditor } from './components/AppBondEditor';
import { EntityBondEditor } from './components/EntityBondEditor';
import { GeneralInfo } from './components/GeneralInfo';
import { GeneralList } from './components/GeneralList';
import { LayoutEditor } from './components/LayoutEditor/LayoutEditor';
import { Preview } from './components/Preview';
import { ViewVarEditor } from './components/ViewVarEditor';
import { DOC_ID, useOptionListForDocs } from '@components/elements/Docs/resources';
import { ViewLogs } from './components/ViewLogs';
import { Modal } from '@components/elements/Modal';
import { CyForm } from '@components/smart/CyForm';
import { CyWrapperContext } from '@components/smart/CyWrapper';

export const ViewEditor = () => {
  const { pathname } = useLocation();
  const { useAction } = useContext(CyWrapperContext);
  const [queryParamState] = useQueryParamState();
  const itemId = queryParamState[QUERY_PARAM.RECORD_ID];
  const [original, internalSetOriginal] = useState<ViewModel.View>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [formValue, setFormValue] = useState<any>(false);

  const isNew = !isSavedRecord(original);
  const docIds = useMemo(
    () => [
      DOC_ID.VIEWS_OVERVIEW,
      DOC_ID.VIEWS_DATA,
      DOC_ID.VIEWS_LAYOUTS,
      DOC_ID.VIEWS_MODALS,
      DOC_ID.VIEWS_CORE_CUSTOM,
      DOC_ID.VIEWS_ACTIONS,
      DOC_ID.VIEWS_SCOPES,
      DOC_ID.VIEWS_EFFECT_COMPOS,
      DOC_ID.VIEWS_CYVIEW_OVERVIEW,
      DOC_ID.VIEWS_CYVIEW_SCOPES,
      DOC_ID.VIEWS_FALLBACKS
    ],
    []
  );
  const optionListForDocs = useOptionListForDocs(docIds);

  const setOriginal = useCallback(
    (view: ViewModel.View) =>
      internalSetOriginal(mergeTruthy(normalize.view(view, false), { metadata: { clientEngineVersion: ENV.CLIENT_ENGINE_VERSION } } as any)),
    []
  );

  const routeList = useMemo(
    () =>
      [
        { route: BUILDER_ROUTE.VIEW_EDITOR.CHILDREN.LIST, label: null },
        { route: BUILDER_ROUTE.VIEW_EDITOR.CHILDREN.GENERAL, label: TRANS.client.nav.builder.tabs.details },
        { route: BUILDER_ROUTE.VIEW_EDITOR.CHILDREN.VIEW, label: TRANS.client.nav.builder.tabs.layout },
        { route: BUILDER_ROUTE.VIEW_EDITOR.CHILDREN.VARS, label: TRANS.client.nav.builder.tabs.vars },
        !isNew && { route: BUILDER_ROUTE.VIEW_EDITOR.CHILDREN.PREVIEW, label: TRANS.client.nav.builder.tabs.preview },
        !isNew && { route: BUILDER_ROUTE.VIEW_EDITOR.CHILDREN.LOGS, label: TRANS.client.nav.builder.tabs.logs },
        !isNew && { route: BUILDER_ROUTE.VIEW_EDITOR.CHILDREN.TAG_BOND, label: TRANS.client.nav.builder.tabs.linkedApps },
        !isNew && { route: BUILDER_ROUTE.VIEW_EDITOR.CHILDREN.ENTITY_BOND, label: TRANS.client.nav.builder.tabs.linkedCollections }
      ].filter(Boolean),
    [isNew]
  );

  const onSave = useEditorHomeOnSave(ApiModel.ApiEntity.VIEW);
  const onRemove = useEditorHomeOnRemove(ApiModel.ApiEntity.VIEW);

  useEditorHomeEffect({ itemId, collectionId: ApiModel.ApiEntity.VIEW, setOriginal, newItemGetter: getNewView, setLoading });

  const onNavigateTo = useAction('dispatchNavigateTo');

  const onOpen = useCallback(() => {
    if (isEmpty(original?.model?.schema?.properties)) {
      return onNavigateTo({ path: original?.id, openInNewTab: true });
    }

    setIsModalOpen(true);
  }, [original, onNavigateTo]);

  return (
    <>
      {isModalOpen && (
        <Modal
          title="Open view"
          description="This view has an input defined, make your selection to navigate"
          open={true}
          onClose={() => setIsModalOpen(false)}
        >
          <CyForm
            maxColumns={2}
            model={original.model}
            ctaLabel="Open"
            value={{ record: formValue } as any}
            onChange={({ record }) => setFormValue(record) as any}
            actionListChildren={[
              {
                important: true,
                label: 'Open',
                onClick: (() => {
                  onNavigateTo({ path: original?.id, qs: formValue, openInNewTab: true });
                  setFormValue(null);
                }) as any
              }
            ]}
          />
        </Modal>
      )}
      <EditorHome
        isLoading={isLoading}
        editorTitle={SECTION_NAME.VIEWS}
        title={original?.name || TRANS.client.nav.builder.newNames.views}
        item={original}
        subtitle={BUILDER_DESCRIPTION.VIEWS}
        icon={original?.recordImage}
        color={original?.recordColor}
        /** header */
        routeList={routeList}
        rootPath={BUILDER_ROUTE.VIEW_EDITOR.ROOT}
        isCurrentDraft={!isNew}
        hideSubHeader={['/', BUILDER_ROUTE.VIEW_EDITOR.ROOT, `${BUILDER_ROUTE.VIEW_EDITOR.ROOT}${BUILDER_ROUTE.VIEW_EDITOR.CHILDREN.LIST}`].includes(pathname)}
        onSave={onSave}
        onRemove={onRemove}
        onSetOriginalValue={setOriginal}
        additionalButtonList={!isNew && [{ icon: 'zoom_in', label: TRANS.client.buttons.open, onClick: onOpen as any }]}
        showCleanView={true}
        tabsIOptionMenu={optionListForDocs}
        activityLogConfig={{ collectionId: ApiModel.ApiEntity.VIEW, recordId: !isNew && itemId }}
      >
        <Routes>
          <Route path={BUILDER_ROUTE.VIEW_EDITOR.CHILDREN.LIST} element={<GeneralList />} />
          <Route path={BUILDER_ROUTE.VIEW_EDITOR.CHILDREN.GENERAL} element={<GeneralInfo />} />
          <Route path={BUILDER_ROUTE.VIEW_EDITOR.CHILDREN.VIEW} element={<LayoutEditor />} />
          <Route path={BUILDER_ROUTE.VIEW_EDITOR.CHILDREN.LOGS} element={<ViewLogs />} />
          <Route path={BUILDER_ROUTE.VIEW_EDITOR.CHILDREN.TAG_BOND} element={<AppBondEditor />} />
          <Route path={BUILDER_ROUTE.VIEW_EDITOR.CHILDREN.ENTITY_BOND} element={<EntityBondEditor />} />
          <Route path={BUILDER_ROUTE.VIEW_EDITOR.CHILDREN.PREVIEW} element={<Preview />} />
          <Route path={BUILDER_ROUTE.VIEW_EDITOR.CHILDREN.VARS} element={<ViewVarEditor />} />
          <Route path="*" element={<GeneralList />} />
        </Routes>
      </EditorHome>
    </>
  );
};
